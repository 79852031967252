import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithFallback from './ImageWithFallback';

const BlogList = ({ posts }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {posts.map((post) => (
        <article
          key={post.id}
          className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
        >
          <Link to={`/blog/${post.slug}`}>
            <ImageWithFallback
              src={post.image}
              alt={post.title}
              className="w-full h-48 object-cover"
              width={400}
              height={300}
            />
          </Link>
          <div className="p-6">
            <h2 className="text-xl font-bold mb-2">
              <Link to={`/blog/${post.slug}`} className="hover:text-primary">
                {post.title}
              </Link>
            </h2>
            <p className="text-gray-600 mb-4">{post.excerpt}</p>
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-500">{post.date}</span>
              <Link
                to={`/blog/${post.slug}`}
                className="text-primary hover:text-primary/80"
              >
                Devamını Oku →
              </Link>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
};

export default BlogList; 