const blogPosts = [
  {
    id: 1,
    slug: 'otomatik-kepenk-sistemleri-nedir',
    title: 'Otomatik Kepenk Sistemleri Nedir? Avantajları Nelerdir?',
    excerpt: 'Otomatik kepenk sistemleri, modern işletmelerin güvenlik ve kullanım kolaylığı ihtiyaçlarını karşılayan teknolojik çözümlerdir.',
    content: `
    <h2 class="text-primary">Otomatik Kepenk Sistemlerinin Avantajları</h2>
        
    <p>Otomatik kepenk sistemleri, işyerlerinizin güvenliğini sağlarken aynı zamanda kullanım kolaylığı sunan modern çözümlerdir. Bu sistemler, elektrikli motorlar sayesinde uzaktan kumanda ile kontrol edilebilir ve manuel kullanım imkanı da sunar.</p>

      <h3 class="text-primary">Güvenlik</h3>
      <p>Yüksek güvenlik standartlarına sahip otomatik kepenkler, işyerinizi hırsızlık ve vandalizme karşı korur. Dayanıklı malzemeler ve gelişmiş kilit sistemleri ile maksimum güvenlik sağlar.</p>

      <h3 class="text-primary">Kullanım Kolaylığı</h3>
      <p>Uzaktan kumanda sistemi sayesinde tek tuşla açılıp kapanabilir. Acil durumlarda manuel kullanım imkanı da mevcuttur.</p>

      <h3 class="text-primary">Enerji Tasarrufu</h3>
      <p>İyi yalıtım özellikleri sayesinde ısı kaybını önler, böylece enerji tasarrufu sağlar. Özellikle kış aylarında ısıtma maliyetlerini düşürür.</p>

      <h3 class="text-primary">Estetik Görünüm</h3>
      <p>Modern tasarımları ile işyerinize şık bir görünüm katar. Farklı renk ve model seçenekleri ile her türlü mimari yapıya uyum sağlar.</p>

      <h2 class="text-primary">Otomatik Kepenk Sistemlerinin Bakımı</h2>
      <p>Düzenli bakım, kepenk sistemlerinizin ömrünü uzatır ve sorunsuz çalışmasını sağlar. Profesyonel servis desteği ile:</p>
      <ul>
        <li>Motor bakımı</li>
        <li>Yağlama işlemleri</li>
        <li>Elektronik aksam kontrolü</li>
        <li>Güvenlik testleri</li>
      </ul>
      <p>yapılmalıdır.</p>
    `,
    image: '/assets/service/service-1.webp',
    author: 'Olgun Kepenk Teknik Ekibi',
    date: '2024-03-20',
    tags: ['otomatik kepenk', 'güvenlik sistemleri', 'işyeri güvenliği'],
    metaDescription: 'Otomatik kepenk sistemleri hakkında detaylı bilgi. Avantajları, kullanım kolaylığı ve bakım gereksinimleri hakkında profesyonel rehber.',
    metaKeywords: 'otomatik kepenk sistemleri, kepenk güvenliği, işyeri güvenlik sistemleri',
  },
  {
    id: 2,
    slug: 'kepenk-ariza-nedenleri',
    title: 'En Sık Karşılaşılan Kepenk Arızaları ve Çözümleri',
    excerpt: 'Kepenk sistemlerinde karşılaşılan yaygın sorunlar ve bunların profesyonel çözüm yöntemleri hakkında detaylı bilgi.',
    content: `
      <p>Otomatik kepenk sistemleri, düzenli bakım ve doğru kullanım ile uzun yıllar sorunsuz çalışır. Ancak bazı durumlarda çeşitli arızalar ortaya çıkabilir. İşte en sık karşılaşılan kepenk arızaları ve çözümleri:</p>

      <h2 class="text-primary">Motor Arızaları</h2>
      <p>Motor arızaları, kepenk sistemlerinde en sık karşılaşılan sorunların başında gelir. Genellikle aşağıdaki sebeplerden kaynaklanır:</p>
      <ul>
        <li>Aşırı kullanım</li>
        <li>Bakım eksikliği</li>
        <li>Elektrik dalgalanmaları</li>
        <li>Yağlama yetersizliği</li>
      </ul>

      <h2 class="text-primary">Kumanda Problemleri</h2>
      <p>Kumanda sorunları genellikle pil zayıflaması veya elektronik aksam arızalarından kaynaklanır. Çözüm için:</p>
      <ul>
        <li>Pil kontrolü</li>
        <li>Kumanda resetleme</li>
        <li>Alıcı kart kontrolü</li>
      </ul>

      <h2 class="text-primary">Mekanik Sorunlar</h2>
      <p>Mekanik aksamda oluşan sorunlar genellikle:</p>
      <ul>
        <li>Ray sisteminde deformasyon</li>
        <li>Rulman aşınmaları</li>
        <li>Yay sistemi problemleri</li>
      </ul>
      <p>şeklinde ortaya çıkar.</p>

      <h2 class="text-primary">Arıza Önleme ve Bakım</h2>
      <p>Düzenli bakım ile birçok arızanın önüne geçebilirsiniz. Önerilen bakım periyodu 6 aydır. Bakım sırasında:</p>
      <ul>
        <li>Genel sistem kontrolü</li>
        <li>Yağlama işlemleri</li>
        <li>Güvenlik testleri</li>
        <li>Elektronik aksam kontrolü</li>
      </ul>
      <p>yapılmalıdır.</p>
    `,
    image: '/assets/service/service-2.webp',
    author: 'Olgun Kepenk Teknik Servisi',
    date: '2024-03-19',
    tags: ['kepenk arızaları', 'kepenk tamiri', 'kepenk bakımı'],
    metaDescription: 'Kepenk sistemlerinde sık karşılaşılan arızalar ve profesyonel çözüm yöntemleri. Arıza önleme ve bakım tavsiyeleri.',
    metaKeywords: 'kepenk arızaları, kepenk tamiri, otomatik kepenk bakımı, kepenk servisi',
  },
  {
    id: 3,
    slug: 'kepenk-guvenlik-sistemleri',
    title: 'Modern Kepenk Güvenlik Sistemleri ve Özellikleri',
    excerpt: 'Modern kepenk güvenlik sistemleri, işyeri güvenliğini en üst seviyeye çıkaran teknolojik çözümler sunar.',
    content: `
    
    <h2 class="text-primary">Modern Kepenk Güvenlik Özellikleri</h2>
    
    <p>Modern kepenk güvenlik sistemleri, gelişen teknoloji ile birlikte işyerleriniz için maksimum güvenlik sağlar. Bu sistemler, klasik güvenlik önlemlerinin ötesinde akıllı çözümler sunar.</p>

      <h3 class="text-primary">Akıllı Kilit Sistemleri</h3>
      <p>Modern kepenkler, gelişmiş kilit sistemleri ile donatılmıştır:</p>
      <ul>
        <li>Şifreli giriş sistemleri</li>
        <li>Parmak izi okuyucular</li>
        <li>RFID kart okuyucular</li>
        <li>Mobil uygulama kontrolü</li>
      </ul>

      <h3 class="text-primary">Alarm Sistemleri</h3>
      <p>Entegre alarm sistemleri şunları içerir:</p>
      <ul>
        <li>Hareket sensörleri</li>
        <li>Darbe sensörleri</li>
        <li>Ses alarmları</li>
        <li>Mobil bildirim sistemi</li>
      </ul>

      <h2 class="text-primary">Güvenlik Sistemlerinin Avantajları</h2>
      <p>Modern güvenlik sistemleri şu avantajları sunar:</p>
      <ul>
        <li>7/24 izleme imkanı</li>
        <li>Uzaktan kontrol</li>
        <li>Acil durum müdahalesi</li>
        <li>Olay kaydı tutma</li>
      </ul>

      <h2 class="text-primary">Bakım ve Kontrol</h2>
      <p>Güvenlik sistemlerinin etkin çalışması için:</p>
      <ul>
        <li>Düzenli sistem kontrolü</li>
        <li>Yazılım güncellemeleri</li>
        <li>Sensör kalibrasyonu</li>
        <li>Güvenlik testleri</li>
      </ul>
      <p>yapılmalıdır.</p>
    `,
    image: '/assets/service/service-3.webp',
    author: 'Olgun Kepenk Güvenlik Uzmanı',
    date: '2024-03-18',
    tags: ['kepenk güvenliği', 'güvenlik sistemleri', 'akıllı kepenk'],
    metaDescription: 'Modern kepenk güvenlik sistemleri ve özellikleri hakkında detaylı bilgi. Akıllı kilit sistemleri, alarm sistemleri ve güvenlik avantajları.',
    metaKeywords: 'kepenk güvenlik sistemleri, akıllı kepenk, güvenlik teknolojileri, işyeri güvenliği',
  },
  {
    id: 4,
    slug: "otomatik-kepenk-secerken-dikkat-edilmesi-gerekenler",
    title: "Otomatik Kepenk Seçerken Dikkat Edilmesi Gerekenler",
    excerpt: "İşyeriniz için en uygun otomatik kepenk sistemini seçmek için bilmeniz gereken önemli faktörler ve öneriler.",
    content: `
      <p>Otomatik kepenk sistemleri, işyerinizin güvenliği ve kullanım kolaylığı için önemli bir yatırımdır. Doğru kepenk sistemini seçmek için dikkat edilmesi gereken birçok faktör bulunmaktadır.</p>

      <h2 class="text-primary">Mekan Özellikleri ve Gereksinimler</h2>
      <p>İşyerinizin özellikleri ve ihtiyaçları, kepenk seçiminde belirleyici rol oynar:</p>
      <ul>
        <li>Açıklık ölçüleri (genişlik ve yükseklik)</li>
        <li>Kullanım sıklığı</li>
        <li>Güvenlik gereksinimleri</li>
        <li>Hava koşullarına dayanıklılık ihtiyacı</li>
      </ul>

      <h2 class="text-primary">Malzeme Seçimi</h2>
      <p>Farklı malzeme seçenekleri farklı avantajlar sunar:</p>
      <ul>
        <li>Galvaniz çelik: Dayanıklı ve ekonomik</li>
        <li>Alüminyum: Hafif ve paslanmaz</li>
        <li>Poliüretan dolgulu: Isı ve ses yalıtımı sağlar</li>
        <li>Perfore: Havalandırma ve görünürlük sağlar</li>
      </ul>

      <h2 class="text-primary">Motor ve Kontrol Sistemleri</h2>
      <p>Motor seçimi ve kontrol sistemleri kullanım kolaylığını belirler:</p>
      <ul>
        <li>Motor gücü ve kapasitesi</li>
        <li>Uzaktan kumanda özellikleri</li>
        <li>Akıllı kontrol sistemleri</li>
        <li>Manuel kullanım imkanı</li>
      </ul>

      <h2 class="text-primary">Montaj ve Servis</h2>
      <p>Profesyonel montaj ve servis desteği uzun ömürlü kullanım sağlar:</p>
      <ul>
        <li>Uzman montaj ekibi</li>
        <li>Garanti kapsamı</li>
        <li>7/24 servis desteği</li>
        <li>Periyodik bakım hizmetleri</li>
      </ul>
    `,
    image: "/assets/service/service-1.webp",
    author: "Olgun Kepenk",
    date: "2024-03-20",
    tags: ["Otomatik Kepenk", "Kepenk Seçimi", "Güvenlik Sistemleri", "İşyeri Güvenliği"],
    metaDescription: "Otomatik kepenk seçerken dikkat edilmesi gereken önemli faktörler, malzeme seçimi, motor sistemleri ve montaj hizmetleri hakkında detaylı bilgiler.",
    metaKeywords: "otomatik kepenk seçimi, kepenk malzemeleri, kepenk motoru, kepenk montajı, işyeri güvenliği"
  },
  {
    id: 5,
    slug: "kepenk-sistemlerinde-enerji-tasarrufu",
    title: "Kepenk Sistemlerinde Enerji Tasarrufu Nasıl Sağlanır?",
    excerpt: "Otomatik kepenk sistemlerinizde enerji verimliliğini artırmanın ve maliyetleri düşürmenin yolları.",
    content: `
      <p>Enerji tasarrufu, işletmeler için giderek daha önemli hale gelmektedir. Doğru kepenk sistemi seçimi ve kullanımı ile önemli ölçüde enerji tasarrufu sağlanabilir.</p>

      <h2 class="text-primary">Yalıtımlı Kepenk Sistemleri</h2>
      <p>Modern yalıtımlı kepenk sistemleri şu avantajları sunar:</p>
      <ul>
        <li>Isı kaybını minimize eder</li>
        <li>Soğutma/ısıtma maliyetlerini düşürür</li>
        <li>Ses yalıtımı sağlar</li>
        <li>Dayanıklı ve uzun ömürlüdür</li>
      </ul>

      <h2 class="text-primary">Akıllı Kontrol Sistemleri</h2>
      <p>Enerji tasarrufunda akıllı kontrol sistemlerinin önemi:</p>
      <ul>
        <li>Otomatik açma/kapama programları</li>
        <li>Sıcaklık sensörleri ile kontrol</li>
        <li>Gün ışığına göre ayarlama</li>
        <li>Uzaktan kontrol imkanı</li>
      </ul>

      <h2 class="text-primary">Bakım ve Optimizasyon</h2>
      <p>Düzenli bakım ve optimizasyon ile enerji verimliliği artırılabilir:</p>
      <ul>
        <li>Motor bakımı ve ayarları</li>
        <li>Yalıtım kontrolü</li>
        <li>Sensör kalibrasyonu</li>
        <li>Sistem optimizasyonu</li>
      </ul>

      <h2 class="text-primary">Enerji Tasarruf Önerileri</h2>
      <p>Günlük kullanımda enerji tasarrufu için öneriler:</p>
      <ul>
        <li>Gereksiz açma/kapama işlemlerinden kaçının</li>
        <li>Programlı kullanım alışkanlığı edinin</li>
        <li>Düzenli bakım yaptırın</li>
        <li>Enerji verimli motorlar kullanın</li>
      </ul>
    `,
    image: "/assets/service/service-2.webp",
    author: "Olgun Kepenk",
    date: "2024-03-21",
    tags: ["Enerji Tasarrufu", "Yalıtımlı Kepenk", "Akıllı Sistemler", "Verimlilik"],
    metaDescription: "Otomatik kepenk sistemlerinde enerji tasarrufu sağlamanın yolları, yalıtımlı sistemler ve akıllı kontrol özellikleri hakkında bilgiler.",
    metaKeywords: "kepenk enerji tasarrufu, yalıtımlı kepenk, akıllı kepenk sistemleri, enerji verimliliği"
  },
  {
    id: 6,
    slug: "kepenk-sistemlerinde-yeni-teknolojiler",
    title: "Kepenk Sistemlerinde Yeni Teknolojiler ve Trendler",
    excerpt: "Otomatik kepenk sistemlerindeki son teknolojik gelişmeler ve gelecek trendleri hakkında detaylı bilgiler.",
    content: `
      <p>Kepenk sistemleri teknolojisi sürekli gelişmekte ve yeni özellikler eklenmektedir. Bu gelişmeler güvenlik, kullanım kolaylığı ve enerji verimliliği açısından önemli avantajlar sağlamaktadır.</p>

      <h2 class="text-primary">Akıllı Ev Entegrasyonu</h2>
      <p>Modern kepenk sistemlerinin akıllı ev özellikleri:</p>
      <ul>
        <li>Mobil uygulama kontrolü</li>
        <li>Sesli komut desteği</li>
        <li>IoT cihaz entegrasyonu</li>
        <li>Otomatik senaryo oluşturma</li>
      </ul>

      <h2 class="text-primary">Gelişmiş Güvenlik Özellikleri</h2>
      <p>Yeni nesil güvenlik teknolojileri:</p>
      <ul>
        <li>Biyometrik kimlik doğrulama</li>
        <li>Yapay zeka destekli güvenlik</li>
        <li>Gelişmiş şifreleme sistemleri</li>
        <li>Anlık güvenlik bildirimleri</li>
      </ul>

      <h2 class="text-primary">Sürdürülebilir Teknolojiler</h2>
      <p>Çevre dostu özellikler ve yenilikler:</p>
      <ul>
        <li>Solar enerjili sistemler</li>
        <li>Enerji geri kazanım teknolojileri</li>
        <li>Çevre dostu malzemeler</li>
        <li>Akıllı enerji yönetimi</li>
      </ul>

      <h2 class="text-primary">Gelecek Trendleri</h2>
      <p>Kepenk sistemlerinin geleceğinde bizi bekleyen yenilikler:</p>
      <ul>
        <li>5G bağlantı desteği</li>
        <li>Yapay zeka optimizasyonu</li>
        <li>Artırılmış gerçeklik uygulamaları</li>
        <li>Predictive maintenance sistemleri</li>
      </ul>
    `,
    image: "/assets/service/service-3.webp",
    author: "Olgun Kepenk",
    date: "2024-03-22",
    tags: ["Yeni Teknolojiler", "Akıllı Sistemler", "IoT", "Güvenlik Teknolojileri"],
    metaDescription: "Otomatik kepenk sistemlerindeki son teknolojik gelişmeler, akıllı ev entegrasyonu ve gelecek trendleri hakkında detaylı bilgiler.",
    metaKeywords: "kepenk teknolojileri, akıllı kepenk, IoT kepenk sistemleri, kepenk güvenlik teknolojileri"
  }
];

export default blogPosts; 