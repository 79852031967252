export const products = [
  {
    id: 1,
    title: 'Tüp Kepenk Motoru 60Nm',
    description: 'Güçlü ve sessiz çalışma özelliğine sahip, uzun ömürlü santral kepenk motoru.',
    image: '/assets/product/tup-motor-1.webp',
    price: '2.000'
  },
  {
    id: 2,
    title: 'Tüp Kepenk Motoru 80Nm',
    description: 'Güçlü ve sessiz çalışma özelliğine sahip, uzun ömürlü santral kepenk motoru.',
    image: '/assets/product/tup-motor-1.webp',
    price: '2.500'
  },
  {
    id: 3,
    title: 'Tüp Kepenk Motoru 100Nm',
    description: 'Güçlü ve sessiz çalışma özelliğine sahip, uzun ömürlü santral kepenk motoru.',
    image: '/assets/product/tup-motor-1.webp',
    price: '2.500'
  },
  {
    id: 4,
    title: 'Tüp Kepenk Motoru 120Nm',
    description: 'Güçlü ve sessiz çalışma özelliğine sahip, uzun ömürlü santral kepenk motoru.',
    image: '/assets/product/tup-motor-1.webp',
    price: '3.400'
  },
  {
    id: 5,
    title: 'Tüp Kepenk Motoru 140Nm',
    description: 'Güçlü ve sessiz çalışma özelliğine sahip, uzun ömürlü santral kepenk motoru.',
    image: '/assets/product/tup-motor-1.webp',
    price: '4.600'
  },
  {
    id: 6,
    title: 'Tüp Kepenk Motoru 190Nm',
    description: 'Güçlü ve sessiz çalışma özelliğine sahip, uzun ömürlü santral kepenk motoru.',
    image: '/assets/product/tup-motor-1.webp',
    price: '4.600'
  },
  {
    id: 7,
    title: 'Tüp Kepenk Motoru 230Nm',
    description: 'Güçlü ve sessiz çalışma özelliğine sahip, uzun ömürlü santral kepenk motoru.',
    image: '/assets/product/tup-motor-1.webp',
    price: '6.500'
  },
  {
    id: 8,
    title: 'Tüp Kepenk Motoru 330Nm',
    description: 'Güçlü ve sessiz çalışma özelliğine sahip, uzun ömürlü santral kepenk motoru.',
    image: '/assets/product/tup-motor-1.webp',
    price: '8.000'
  },
  {
    id: 9,
    title: 'Santral Kepenk Motoru',
    description: 'Yüksek performanslı redüktörlü motor sistemi. Ağır kepenkler için ideal çözüm.',
    image: '/assets/product/ikiz-motor-1.webp',
    price: '4.900'
  },
  {
    id: 10,
    title: 'Zincirli Kepenk Motoru',
    description: 'Manuel kullanım özellikli, elektrik kesintilerinde sorunsuz çalışan motor sistemi.',
    image: '/assets/product/zincirli-motor-1.webp',
    price: '9.500'
  },
  {
    id: 11,
    title: 'Güç Kaynağı 1000 Watt',
    description: 'Güçlü ve güvenli güç kaynağı. Kepenk motorları için gerekli güç sağlar.',
    image: '/assets/product/guc-kaynagi-1.webp',
    price: '4.750'
  },
  {
    id: 12,
    title: 'Güç Kaynağı 1200 Watt',
    description: 'Güçlü ve güvenli güç kaynağı. Kepenk motorları için gerekli güç sağlar.',
    image: '/assets/product/guc-kaynagi-1.webp',
    price: '5.500'
  },
  {
    id: 13,
    title: 'Güç Kaynağı 1700 Watt',
    description: 'Güçlü ve güvenli güç kaynağı. Kepenk motorları için gerekli güç sağlar.',
    image: '/assets/product/guc-kaynagi-1.webp',
    price: '6.500'
  },
  {
    id: 14,
    title: 'Güç Kaynağı 2000 Watt',
    description: 'Güçlü ve güvenli güç kaynağı. Kepenk motorları için gerekli güç sağlar.',
    image: '/assets/product/guc-kaynagi-1.webp',
    price: '8.000'
  },
  {
    id: 15,
    title: 'Alıcı Kart',
    description: 'Tüm kepenk motorlarıyla uyumlu, yüksek hafızalı alıcı kart.',
    image: '/assets/product/alici-kart-1.webp',
    price: '600'
  },
  {
    id: 16,
    title: 'Alıcı Kart',
    description: 'Tüm kepenk motorlarıyla uyumlu, yüksek hafızalı alıcı kart.',
    image: '/assets/product/alici-kart-2.webp',
    price: '750'
  },
  {
    id: 17,
    title: 'Alıcı Kart',
    description: 'Tüm kepenk motorlarıyla uyumlu, yüksek hafızalı alıcı kart.',
    image: '/assets/product/alici-kart-3.webp',
    price: '900'
  },
  {
    id: 18,
    title: 'Kopya Korumalı Kumanda',
    description: '433.92 MHz frekansında çalışan, kopya korumalı, uzun mesafeli kumanda.',
    image: '/assets/product/kepenk-kumandasi-1.webp',
    price: '300'
  },
  {
    id: 19,
    title: 'Kopya Korumalı Kumanda İzmaksan',
    description: '433.92 MHz frekansında çalışan, kopya korumalı, uzun mesafeli kumanda.',
    image: '/assets/product/kepenk-kumandasi-1.webp',
    price: '600'
  },
  {
    id: 20,
    title: 'Kopya Korumalı Kumanda Cuppon',
    description: '433.92 MHz frekansında çalışan, kopya korumalı, uzun mesafeli kumanda.',
    image: '/assets/product/kepenk-kumandasi-1.webp',
    price: '600'
  },
  {
    id: 21,
    title: 'Kopya Korumalı Kumanda Huxo',
    description: '433.92 MHz frekansında çalışan, kopya korumalı, uzun mesafeli kumanda.',
    image: '/assets/product/kepenk-kumandasi-1.webp',
    price: '450'
  }
]; 