import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FiPhone } from 'react-icons/fi';
import { contactConfig } from '../config/contactConfig';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-32 pb-16">
      <Helmet>
        <title>Hakkımızda - Olgun Kepenk | İstanbul'un Güvenilir Kepenk Servisi</title>
        <meta name="description" content="20 yılı aşkın deneyimimizle İstanbul'da otomatik kepenk sistemleri konusunda güvenilir çözümler sunuyoruz. 7/24 kesintisiz hizmet anlayışıyla yanınızdayız." />
        <meta name="keywords" content="olgun kepenk, istanbul kepenk servisi, otomatik kepenk tamiri, kepenk bakım, kepenk onarım" />
      </Helmet>

      <div className="container mx-auto px-4">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
            İstanbul'un Güvenilir Kepenk Servisi
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            20 yılı aşkın sektör deneyimimizle otomatik kepenk sistemleri konusunda İstanbul'un önde gelen firmalarından biriyiz.
          </p>
        </div>

        {/* Values Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
            <div className="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mb-6">
              <svg className="w-8 h-8 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-4">Güvenilirlik</h3>
            <p className="text-gray-600">
              Müşteri memnuniyetini ön planda tutarak, güvenilir ve kaliteli hizmet sunuyoruz.
            </p>
          </div>

          <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
            <div className="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mb-6">
              <svg className="w-8 h-8 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-4">7/24 Hizmet</h3>
            <p className="text-gray-600">
              Acil durumlarınızda 7/24 kesintisiz hizmet vererek her zaman yanınızdayız.
            </p>
          </div>

          <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
            <div className="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mb-6">
              <svg className="w-8 h-8 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-4">Hızlı Çözüm</h3>
            <p className="text-gray-600">
              Deneyimli ekibimizle sorunlarınıza hızlı ve kalıcı çözümler üretiyoruz.
            </p>
          </div>
        </div>

        {/* Experience Section */}
        <div className="bg-gradient-to-r from-primary to-primary-light text-white rounded-2xl p-8 md:p-12 mb-16">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">20+ Yıllık Deneyim</h2>
            <p className="text-lg mb-8">
              İstanbul'da 20 yılı aşkın süredir otomatik kepenk sistemleri konusunda profesyonel hizmet veriyoruz. 
              Deneyimli ekibimiz ve kaliteli hizmet anlayışımızla müşterilerimizin güvenini kazandık.
            </p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              <div>
                <div className="text-4xl font-bold mb-2">500+</div>
                <div className="text-sm">Mutlu Müşteri</div>
              </div>
              <div>
                <div className="text-4xl font-bold mb-2">20+</div>
                <div className="text-sm">Yıllık Deneyim</div>
              </div>
              <div>
                <div className="text-4xl font-bold mb-2">7/24</div>
                <div className="text-sm">Kesintisiz Hizmet</div>
              </div>
              <div>
                <div className="text-4xl font-bold mb-2">1000+</div>
                <div className="text-sm">Tamamlanan Proje</div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact CTA */}
        <div className="mt-12 text-center bg-primary/5 rounded-2xl p-8">
          <h2 className="text-3xl font-bold text-primary mb-6">
            7/24 Kesintisiz Hizmet
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Otomatik kepenk servis ve tamir hizmetlerimiz için hemen bizi arayın.
          </p>
          <a
            href={`tel:${contactConfig.phone}`}
            className="group relative inline-flex items-center gap-4 bg-primary hover:bg-primary-dark text-white px-8 py-4 rounded-full text-xl font-semibold transition-all duration-300 hover:scale-105 hover:shadow-lg overflow-hidden"
          >
            <span className="relative z-10 flex items-center gap-4">
              <span className="relative">
                <FiPhone className="w-6 h-6" />
                <span className="absolute -inset-1 border-2 border-white/40 rounded-full animate-ping" />
                <span className="absolute -inset-2 border-2 border-white/20 rounded-full animate-ping animation-delay-300" />
              </span>
              Hemen Arayın
            </span>
            <span className="absolute inset-0 bg-gradient-to-r from-primary-dark to-primary opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default About; 