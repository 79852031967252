import React, { useEffect } from 'react';
import HomeSlider from '../components/HomeSlider';
import HomeServices from '../components/HomeServices';
import DistrictLinks from '../components/DistrictLinks';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Olgun Kepenk | İstanbul'un Lider Otomatik Kepenk Servisi ve Tamiri</title>
        <meta name="description" content="İstanbul'da 20 yıllık deneyimle otomatik kepenk servisi, kepenk tamiri ve sıfır kepenk hizmetleri. ✓7/24 Acil Servis ✓Garantili Hizmet ✓30 Dakika İçinde Yanınızdayız" />
        <meta name="keywords" content="otomatik kepenk servisi, kepenk tamiri, sıfır kepenk, otomatik kepenk imalatı, istanbul kepenk servisi, kepenk bakım, acil kepenk servisi, kepenk motor tamiri, otomatik kepenk montajı" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Olgun Kepenk | İstanbul'un Lider Otomatik Kepenk Servisi" />
        <meta property="og:description" content="İstanbul'da profesyonel otomatik kepenk servisi, tamir ve bakım hizmetleri. 7/24 acil servis." />
        <meta property="og:image" content="/logo.png" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Olgun Kepenk | İstanbul'un Lider Otomatik Kepenk Servisi" />
        <meta name="twitter:description" content="İstanbul'da profesyonel otomatik kepenk servisi, tamir ve bakım hizmetleri. 7/24 acil servis." />
        <meta name="twitter:image" content="/logo.png" />

        {/* Ek Meta Etiketleri */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Olgun Kepenk" />
        <meta name="geo.region" content="TR-34" />
        <meta name="geo.placename" content="İstanbul" />
        <link rel="canonical" href="https://www.olgunkepenk.com" />

        {/* Schema.org Yapısal Veri */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Olgun Kepenk",
              "image": "/logo.png",
              "description": "İstanbul'da profesyonel otomatik kepenk servisi, tamir ve bakım hizmetleri.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "İstanbul",
                "addressRegion": "İstanbul",
                "addressCountry": "TR"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "41.0082",
                "longitude": "28.9784"
              },
              "url": "https://www.olgunkepenk.com",
              "telephone": "+902121234567",
              "priceRange": "₺₺",
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday"
                ],
                "opens": "00:00",
                "closes": "23:59"
              },
              "sameAs": [
                "https://www.facebook.com/olgunkepenk",
                "https://www.instagram.com/olgunkepenk",
                "https://twitter.com/olgunkepenk"
              ],
              "hasOfferCatalog": {
                "@type": "OfferCatalog",
                "name": "Kepenk Servisleri",
                "itemListElement": [
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Otomatik Kepenk Servisi",
                      "description": "7/24 profesyonel otomatik kepenk servis hizmeti"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Kepenk Tamiri",
                      "description": "Her türlü kepenk arıza ve tamir hizmeti"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Sıfır Kepenk",
                      "description": "Modern ve güvenilir sıfır kepenk sistemleri"
                    }
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <HomeSlider />
      <HomeServices />

      {/* Ana İçerik Bölümü */}
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto">
          <h1 className="text-3xl font-bold text-primary mb-8 text-center">
            İstanbul'un Lider Otomatik Kepenk Servisi
          </h1>
          
          <div className="prose w-full space-y-6 text-gray-700 px-4">
            <p className="lead text-lg">
              20 yılı aşkın deneyimimizle İstanbul'un tüm ilçelerinde profesyonel otomatik kepenk servisi, tamir ve bakım hizmetleri sunuyoruz. Olgun Kepenk olarak, işyerinizin güvenliğini en üst düzeyde tutmak için 7/24 yanınızdayız.
            </p>

            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-primary mb-4">
                Neden Olgun Kepenk?
              </h3>
              <ul className="list-none space-y-3">
                <li className="flex items-start">
                  <svg className="w-6 h-6 text-primary mt-1 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>20 yılı aşkın sektör deneyimi</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-6 h-6 text-primary mt-1 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>7/24 acil servis hizmeti</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-6 h-6 text-primary mt-1 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Garantili yedek parça ve işçilik</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-6 h-6 text-primary mt-1 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Profesyonel ve deneyimli teknik ekip</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-6 h-6 text-primary mt-1 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Uygun fiyat garantisi</span>
                </li>
              </ul>
            </div>

            <div className="grid md:grid-cols-2 gap-8 mt-8">
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-semibold text-primary mb-4">
                  Kepenk Servis Hizmetlerimiz
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Motor değişimi ve tamiri</li>
                  <li>Kumanda ve alıcı kart değişimi</li>
                  <li>Mekanik aksam tamiri</li>
                  <li>Periyodik bakım hizmetleri</li>
                  <li>Yedek parça değişimi</li>
                  <li>Elektronik kart tamiri</li>
                </ul>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-semibold text-primary mb-4">
                  Kepenk Sistemleri
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Galvaniz çelik kepenkler</li>
                  <li>Alüminyum kepenkler</li>
                  <li>Poliüretan dolgulu kepenkler</li>
                  <li>Paslanmaz çelik kepenkler</li>
                  <li>Motorlu panjur sistemleri</li>
                  <li>Otomatik garaj kapıları</li>
                </ul>
              </div>
            </div>

            <div className="bg-primary/5 p-6 rounded-lg mt-8">
              <h3 className="text-xl font-semibold text-primary mb-4">
                7/24 Acil Kepenk Servisi
              </h3>
              <p>
                İstanbul'un tüm ilçelerinde, günün her saati acil kepenk servis hizmeti veriyoruz. Deneyimli ekibimiz, en geç 30 dakika içinde işyerinizde olup sorununuzu çözmek için hazır bekliyor. Kepenk arızaları işyerinizin güvenliğini tehlikeye atabilir, bu yüzden gecikmeden profesyonel destek almanız önemlidir.
              </p>
              <div className="mt-4">
                <Link to="/iletisim" className="inline-flex items-center text-primary hover:text-primary-dark font-medium">
                  Hemen İletişime Geçin
                  <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
            </div>

            {/* Yeni Eklenen İçerik Bölümleri */}
            <div className="bg-white p-6 rounded-lg shadow-sm mt-8">
              <h3 className="text-xl font-semibold text-primary mb-4">
                İstanbul'un Her Noktasında Kepenk Servisi
              </h3>
              <p className="mb-4">
                Avrupa Yakası ve Anadolu Yakası'nın tüm ilçelerinde otomatik kepenk servisi hizmeti veriyoruz. Arnavutköy'den Zeytinburnu'na, Adalar'dan Üsküdar'a kadar İstanbul'un her noktasında profesyonel kepenk tamiri ve bakım hizmetleri sunuyoruz. Her bölge için özel eğitimli servis ekiplerimiz, en kısa sürede yanınızda olmak için hazır bekliyor.
              </p>
              <p>
                Kepenk sistemlerinizin düzenli bakımı, uzun ömürlü ve sorunsuz çalışması için büyük önem taşır. Bu nedenle periyodik bakım hizmetlerimizle olası arızaların önüne geçiyor, işyerinizin güvenliğini maksimum düzeyde tutuyoruz.
              </p>
            </div>

            <div className="grid md:grid-cols-2 gap-8 mt-8">
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-semibold text-primary mb-4">
                  Kepenk Arıza ve Tamir Hizmetleri
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Kepenk motoru arızaları ve tamiri</li>
                  <li>Uzaktan kumanda programlama</li>
                  <li>Şaft ve rulman değişimi</li>
                  <li>Kayış ve makara sistemleri tamiri</li>
                  <li>Elektrik ve kablo sistemleri onarımı</li>
                  <li>Kepenk dişli ve mil tamiri</li>
                  <li>Kepenk ray sistemleri bakımı</li>
                  <li>Acil kilit açma hizmeti</li>
                </ul>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-semibold text-primary mb-4">
                  Profesyonel Kepenk Çözümleri
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Mağaza ve dükkan kepenkler</li>
                  <li>Endüstriyel tesis kepenkler</li>
                  <li>AVM ve plaza kepenk sistemleri</li>
                  <li>Garaj ve otopark kepenkler</li>
                  <li>Yangına dayanıklı kepenkler</li>
                  <li>Şeffaf polikarbon kepenkler</li>
                  <li>Fotoselli kepenk sistemleri</li>
                  <li>Özel tasarım kepenk çözümleri</li>
                </ul>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm mt-8">
              <h3 className="text-xl font-semibold text-primary mb-4">
                Kepenk Bakım ve Onarım Avantajları
              </h3>
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <h4 className="font-semibold text-gray-800 mb-2">Düzenli Bakım ile:</h4>
                  <ul className="list-disc list-inside space-y-2 text-gray-700">
                    <li>Enerji tasarrufu sağlarsınız</li>
                    <li>Arıza riskini minimize edersiniz</li>
                    <li>Kepenk ömrünü uzatırsınız</li>
                    <li>İşletme maliyetlerini düşürürsünüz</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-800 mb-2">Profesyonel Servis ile:</h4>
                  <ul className="list-disc list-inside space-y-2 text-gray-700">
                    <li>7/24 teknik destek alırsınız</li>
                    <li>Orijinal yedek parça kullanılır</li>
                    <li>Garantili hizmet alırsınız</li>
                    <li>Uzman ekipten destek alırsınız</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Yeni SEO İçerik Bölümleri */}
            <div className="bg-white p-6 rounded-lg shadow-sm mt-8">
              <h3 className="text-xl font-semibold text-primary mb-4">
                Otomatik Kepenk Servisi ve Bakım Hizmetleri
              </h3>
              <p className="mb-4">
                İstanbul'da otomatik kepenk servisi denildiğinde akla gelen ilk firma olan Olgun Kepenk, 20 yıllık tecrübesiyle işletmenizin güvenliğini sağlıyor. Kepenk servisi hizmetlerimiz, düzenli bakım ve onarım süreçlerini kapsayan kapsamlı bir yaklaşım sunuyor. Otomatik kepenk sistemlerinizin sorunsuz çalışması için periyodik bakım programları oluşturuyor, olası arızaları önceden tespit ederek önleminizi alıyoruz.
              </p>
              <p>
                Profesyonel kepenk servisi ekibimiz, tüm marka ve model kepenkler için uzman destek sağlıyor. İşletmenizin kesintisiz çalışması için 7/24 hizmet veren servis ağımız, İstanbul'un her noktasında 30 dakika içinde yanınızda.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm mt-8">
              <h3 className="text-xl font-semibold text-primary mb-4">
                Uzman Kepenk Tamiri ve Onarım Çözümleri
              </h3>
              <p className="mb-4">
                Kepenk tamiri konusunda uzmanlaşmış teknik ekibimiz, her türlü arıza için kalıcı çözümler üretiyor. Motor arızası, kumanda problemi, şaft bozulması gibi sık karşılaşılan kepenk sorunlarında hızlı ve etkili tamir hizmeti sunuyoruz. Kepenk tamiri işlemlerimizde orijinal yedek parça kullanıyor, yapılan tüm işlemleri garanti kapsamına alıyoruz.
              </p>
              <div className="grid md:grid-cols-2 gap-4 mt-4">
                <div>
                  <h4 className="font-semibold text-gray-800 mb-2">En Sık Karşılaşılan Arızalar:</h4>
                  <ul className="list-disc list-inside space-y-1 text-gray-700">
                    <li>Motor ve kumanda arızaları</li>
                    <li>Şaft ve yay sisteminde bozulmalar</li>
                    <li>Ray sisteminde deformasyon</li>
                    <li>Elektrik ve sigorta problemleri</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-800 mb-2">Tamir Sürecimiz:</h4>
                  <ul className="list-disc list-inside space-y-1 text-gray-700">
                    <li>Arıza tespiti ve keşif</li>
                    <li>Detaylı maliyet analizi</li>
                    <li>Hızlı onarım süreci</li>
                    <li>Test ve kontrol aşaması</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm mt-8">
              <h3 className="text-xl font-semibold text-primary mb-4">
                Sıfır Kepenk Sistemleri ve Montaj
              </h3>
              <p className="mb-4">
                Sıfır kepenk ihtiyaçlarınız için en son teknoloji ürünleri sunuyoruz. İşletmenizin ihtiyaçlarına özel, yüksek güvenlikli ve uzun ömürlü sıfır kepenk sistemleri ile hizmet veriyoruz. Motorlu, manuel, uzaktan kumandalı, şifreli gibi farklı kontrol seçenekleriyle modern çözümler üretiyoruz.
              </p>
              <div className="mt-4">
                <h4 className="font-semibold text-gray-800 mb-2">Sıfır Kepenk Avantajları:</h4>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>En son teknoloji ürünü sistemler</li>
                  <li>Yüksek güvenlik standartları</li>
                  <li>Enerji tasarruflu motorlar</li>
                  <li>Uzun ömürlü malzeme kalitesi</li>
                  <li>Profesyonel montaj hizmeti</li>
                  <li>Garanti kapsamında kurulum</li>
                </ul>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm mt-8">
              <h3 className="text-xl font-semibold text-primary mb-4">
                Kepenk Sistemleri ve Teknik Özellikler
              </h3>
              <p className="mb-4">
                Modern kepenk sistemleri, işletmenizin güvenliğini ve işlevselliğini bir arada sunuyor. Otomatik kepenk sistemlerimiz, yüksek kaliteli malzemeler ve ileri teknoloji ile üretiliyor. Her işletmenin özel ihtiyaçlarına uygun çözümler geliştiriyor, montaj sonrası teknik destek ve bakım hizmetleri sunuyoruz.
              </p>
              <div className="grid md:grid-cols-2 gap-6 mt-4">
                <div>
                  <h4 className="font-semibold text-gray-800 mb-2">Teknik Özellikler:</h4>
                  <ul className="list-disc list-inside space-y-1 text-gray-700">
                    <li>Güçlendirilmiş motor sistemleri</li>
                    <li>Akıllı kontrol üniteleri</li>
                    <li>Güvenlik sensörleri</li>
                    <li>Yedek güç kaynağı desteği</li>
                    <li>Uzaktan erişim sistemleri</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-800 mb-2">Güvenlik Özellikleri:</h4>
                  <ul className="list-disc list-inside space-y-1 text-gray-700">
                    <li>Otomatik kilit sistemleri</li>
                    <li>Darbe sensörleri</li>
                    <li>Acil durum müdahale sistemi</li>
                    <li>Şifreli giriş seçeneği</li>
                    <li>Manuel kullanım imkanı</li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <DistrictLinks />
    </>
  );
};

export default Home; 