import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FiPhone, FiMail, FiMapPin, FiClock } from 'react-icons/fi';
import { BsWhatsapp } from 'react-icons/bs';
import { contactConfig } from '../config/contactConfig';
import emailjs from '@emailjs/browser';

const ContactInfo = ({ icon: Icon, title, content, href }) => (
  <a
    href={href}
    target={href?.startsWith('http') ? '_blank' : undefined}
    rel={href?.startsWith('http') ? 'noopener noreferrer' : undefined}
    className="flex items-start space-x-4 p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
  >
    <div className="flex-shrink-0">
      <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center">
        <Icon className="w-6 h-6 text-primary" />
      </div>
    </div>
    <div>
      <h3 className="text-lg font-semibold text-gray-900 mb-1">{title}</h3>
      <p className="text-gray-600">{content}</p>
    </div>
  </a>
);

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const [status, setStatus] = useState({
    submitting: false,
    success: false,
    error: false,
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ submitting: true, success: false, error: false, message: '' });

    try {
      const result = await emailjs.send(
        'service_yyx83wu', // Email.js Service ID
        'template_lbvt3fh', // Email.js Template ID
        {
          from_name: formData.name,
          from_email: formData.email,
          from_phone: formData.phone,
          message: formData.message,
          to_email: contactConfig.email
        },
        '3fyVeoidCVHQBc_Eh' // Email.js Public Key
      );

      if (result.status === 200) {
        setStatus({
          submitting: false,
          success: true,
          error: false,
          message: 'Mesajınız başarıyla gönderildi. En kısa sürede size dönüş yapacağız.'
        });
        setFormData({ name: '', phone: '', email: '', message: '' });
      }
    } catch (error) {
      setStatus({
        submitting: false,
        success: false,
        error: true,
        message: 'Mesajınız gönderilirken bir hata oluştu. Lütfen tekrar deneyin.'
      });
    }
  };

  return (
    <div className="pt-32 pb-16 bg-gray-50">
      <Helmet>
        <title>İletişim - Olgun Kepenk | 7/24 Otomatik Kepenk Servisi</title>
        <meta 
          name="description" 
          content="İstanbul'da otomatik kepenk servisi için 7/24 bize ulaşın. ✓Aynı Gün Servis ✓Profesyonel Ekip ✓Garantili Hizmet. Hemen arayın!" 
        />
        <meta 
          name="keywords" 
          content="olgun kepenk iletişim, kepenk servisi iletişim, otomatik kepenk servisi telefon, İstanbul kepenk servisi iletişim" 
        />
      </Helmet>

      <div className="container mx-auto px-4">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
            7/24 Yanınızdayız
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            İstanbul'un tüm ilçelerinde otomatik kepenk servis ve tamir hizmetleri için bize ulaşın.
          </p>
        </div>

        {/* Contact Info Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
          <ContactInfo
            icon={FiPhone}
            title="Telefon"
            content={contactConfig.phoneDisplay}
            href={`tel:${contactConfig.phone}`}
          />
          <ContactInfo
            icon={BsWhatsapp}
            title="WhatsApp"
            content="7/24 WhatsApp Destek"
            href={`https://wa.me/${contactConfig.whatsapp}?text=${encodeURIComponent(contactConfig.whatsappMessage)}`}
          />
          <ContactInfo
            icon={FiMail}
            title="E-posta"
            content={contactConfig.email}
            href={`mailto:${contactConfig.email}`}
          />
          <ContactInfo
            icon={FiClock}
            title="Çalışma Saatleri"
            content="7/24 Hizmet"
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-16">
          {/* Contact Form */}
          <div className="bg-white rounded-2xl shadow-lg p-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              İletişim Formu
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="name">
                  Adınız Soyadınız
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="phone">
                  Telefon Numaranız
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="email">
                  E-posta Adresiniz
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="message">
                  Mesajınız
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary"
                  required
                ></textarea>
              </div>

              {/* Status Messages */}
              {status.message && (
                <div className={`p-4 rounded-lg ${status.success ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'}`}>
                  {status.message}
                </div>
              )}

              <button
                type="submit"
                disabled={status.submitting}
                className={`w-full bg-primary text-white py-3 rounded-lg transition-colors ${
                  status.submitting 
                    ? 'opacity-50 cursor-not-allowed' 
                    : 'hover:bg-primary-dark'
                }`}
              >
                {status.submitting ? 'Gönderiliyor...' : 'Gönder'}
              </button>
            </form>
          </div>

          {/* Map */}
          <div className="bg-white rounded-2xl shadow-lg p-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              Adres Bilgilerimiz
            </h2>
            <div className="mb-6">
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center">
                    <FiMapPin className="w-6 h-6 text-primary" />
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-1">
                    Ofis Adresimiz
                  </h3>
                  <p className="text-gray-600">
                    {contactConfig.address}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative h-[400px] rounded-lg overflow-hidden">
              <iframe
                title="Olgun Kepenk Konum"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.6504900120997!2d28.89406491537653!3d40.99531297930244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab0ea0b9b2d6f%3A0x7c7b8d2d5c2d1b1a!2sG%C3%BCven%20Kepenk!5e0!3m2!1str!2str!4v1625582000000!5m2!1str!2str"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-primary/5 rounded-2xl p-8">
          <h2 className="text-3xl font-bold text-primary mb-6">
            7/24 Acil Kepenk Servisi
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            İstanbul'un tüm ilçelerinde otomatik kepenk servis ve tamir hizmetlerimiz için hemen bizi arayın.
          </p>
          <a
            href={`tel:${contactConfig.phone}`}
            className="group relative inline-flex items-center gap-4 bg-primary hover:bg-primary-dark text-white px-8 py-4 rounded-full text-xl font-semibold transition-all duration-300 hover:scale-105 hover:shadow-lg overflow-hidden"
          >
            <span className="relative z-10 flex items-center gap-4">
              <span className="relative">
                <FiPhone className="w-6 h-6" />
                <span className="absolute -inset-1 border-2 border-white/40 rounded-full animate-ping" />
                <span className="absolute -inset-2 border-2 border-white/20 rounded-full animate-ping animation-delay-300" />
              </span>
              Hemen Arayın
            </span>
            <span className="absolute inset-0 bg-gradient-to-r from-primary-dark to-primary opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact; 