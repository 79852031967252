import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FiPhoneCall } from 'react-icons/fi';
import { BsWhatsapp } from 'react-icons/bs';
import { districts } from '../config/districts';
import { contactConfig } from '../config/contactConfig';

const DistrictDetail = () => {
  const { district } = useParams();
  const districtData = districts[district];

  if (!districtData) {
    return <div>Sayfa bulunamadı.</div>;
  }

  return (
    <>
      <Helmet>
        <title>{districtData.title}</title>
        <meta name="description" content={districtData.description} />
        <meta name="keywords" content={districtData.metaKeywords} />
      </Helmet>

      {/* Hero Image */}
      <div className="relative h-[300px] mb-8">
        <img
          src={districtData.heroImage}
          alt={districtData.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-white text-center p-4">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">{districtData.title}</h1>
          <p className="text-lg max-w-3xl">{districtData.description}</p>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        {/* Content Sections */}
        <div className="space-y-8">
          {districtData.content.map((section, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold text-primary mb-4">{section.title}</h2>
              <div className="prose max-w-none">
                {section.text.split('\n\n').map((paragraph, idx) => (
                  <p key={idx} className="mb-4 text-gray-700">
                    {paragraph.trim()}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Service Areas */}
        <div className="bg-white rounded-lg shadow-lg p-6 mt-8">
          <h2 className="text-2xl font-bold text-primary mb-4">Hizmet Verdiğimiz Mahalleler</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {districtData.neighborhoods.map((neighborhood, index) => (
              <div key={index} className="bg-gray-50 p-3 rounded-lg text-center">
                {neighborhood}
              </div>
            ))}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="bg-white rounded-lg shadow-lg p-6 mt-8">
          <h2 className="text-2xl font-bold text-primary mb-6">Sık Sorulan Sorular</h2>
          <div className="space-y-4">
            {districtData.faqs.map((faq, index) => (
              <div key={index} className="border-b pb-4">
                <h3 className="text-lg font-semibold mb-2">{faq.question}</h3>
                <p className="text-gray-700">{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Contact Buttons */}
        <div className="fixed bottom-4 right-4 flex flex-col space-y-4">
          <a
            href={`tel:${contactConfig.phone}`}
            className="flex items-center justify-center gap-2 bg-[#00bcd4] text-white py-3 px-6 rounded-lg hover:bg-[#00acc1] transition-colors"
          >
            <FiPhoneCall className="text-xl" />
            Hemen Ara
          </a>
          <a
            href={`https://wa.me/${contactConfig.whatsapp}?text=Merhaba, ${district} bölgesinde kepenk servisine ihtiyacım var.`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center gap-2 bg-[#25d366] text-white py-3 px-6 rounded-lg hover:bg-[#22c55e] transition-colors"
          >
            <BsWhatsapp className="text-xl" />
            WhatsApp
          </a>
        </div>
      </div>
    </>
  );
};

export default DistrictDetail; 