import React from 'react';
import { Helmet } from 'react-helmet';
import BlogList from '../components/BlogList';
import blogPosts from '../config/blogPosts';

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>Blog | Olgun Kepenk</title>
        <meta
          name="description"
          content="Otomatik kepenk sistemleri, bakım, onarım ve güvenlik çözümleri hakkında profesyonel bilgiler içeren blog yazıları."
        />
        <meta
          name="keywords"
          content="kepenk blog, otomatik kepenk bilgileri, kepenk bakımı, kepenk tamiri, kepenk sistemleri"
        />
      </Helmet>

      <div className="container mx-auto px-4 py-12">
        <header className="text-center mb-12">
          <h1 className="text-4xl font-bold text-primary mb-4">
            Olgun Kepenk Blog
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Otomatik kepenk sistemleri, bakım ipuçları ve güvenlik çözümleri hakkında
            profesyonel bilgiler edinebileceğiniz blog yazılarımız.
          </p>
        </header>

        <BlogList posts={blogPosts} />
      </div>
    </>
  );
};

export default Blog; 