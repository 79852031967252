import React from 'react';
import { Link } from 'react-router-dom';
import { districts } from '../config/districts';

const DistrictLinks = () => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-primary mb-8">
          İstanbul'un Tüm İlçelerinde Kepenk Servisi
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
          {Object.keys(districts).map((district) => (
            <Link
              key={district}
              to={`/${district}`}
              className="bg-gray-50 hover:bg-primary hover:text-white p-4 rounded-lg text-center transition-colors"
            >
              {districts[district].title.split('|')[0].trim()}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DistrictLinks; 