import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import BlogDetail from '../components/BlogDetail';
import blogPosts from '../config/blogPosts';

const BlogPost = () => {
  const { slug } = useParams();
  const post = blogPosts.find((post) => post.slug === slug);

  if (!post) {
    return <Navigate to="/blog" replace />;
  }

  return (
    <div className="container mx-auto px-4 py-12">
      <BlogDetail post={post} />
    </div>
  );
};

export default BlogPost; 