export const districts = {
  'adalar': {
    title: 'Adalar Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Adalar\'da profesyonel otomatik kepenk servisi, tamiri ve bakım hizmetleri. ✓7/24 Acil Servis ✓Uygun Fiyat ✓20 Yıllık Deneyim',
    heroImage: '/assets/service/service-1.webp',
    content: [
      {
        title: 'Adalar Kepenk Servisi',
        text: `Adalar'da otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Adalar'ın tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Adalar Kepenk Tamiri',
        text: `Adalar'da her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Adalar'da kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Adalar Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Adalar'da ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Adalar\'da kepenk servisi ne kadar sürede gelir?',
        answer: 'Adalar\'ın tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Adalar\'da hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Adalar\'da kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Adalar kepenk servisi, Adalar kepenk tamiri, Adalar otomatik kepenk, Adalar kepenk tamircisi, Büyükada kepenk servisi, Heybeliada kepenk tamiri',
    neighborhoods: ['Burgazada', 'Büyükada', 'Heybeliada', 'Kınalıada', 'Sedefadası']
  },
  'arnavutkoy': {
    title: 'Arnavutköy Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Arnavutköy\'de profesyonel otomatik kepenk servisi, tamiri ve bakım hizmetleri. ✓7/24 Acil Servis ✓Uygun Fiyat ✓20 Yıllık Deneyim',
    heroImage: '/assets/service/service-2.webp',
    content: [
      {
        title: 'Arnavutköy Kepenk Servisi',
        text: `Arnavutköy'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Arnavutköy'ün tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Arnavutköy Kepenk Tamiri',
        text: `Arnavutköy'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Arnavutköy'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Arnavutköy Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Arnavutköy'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Arnavutköy\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Arnavutköy\'ün tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Arnavutköy\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Arnavutköy\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Arnavutköy kepenk servisi, Arnavutköy kepenk tamiri, Arnavutköy otomatik kepenk, Arnavutköy kepenk tamircisi, Hadımköy kepenk servisi, Taşoluk kepenk tamiri',
    neighborhoods: ['Hadımköy', 'Taşoluk', 'Haraççı', 'Bolluca', 'İmrahor', 'Ömerli', 'Yavuz Selim']
  },
  'atasehir': {
    title: 'Ataşehir Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Ataşehir\'de profesyonel otomatik kepenk servisi, tamiri ve bakım hizmetleri. ✓7/24 Acil Servis ✓Uygun Fiyat ✓20 Yıllık Deneyim',
    heroImage: '/assets/service/service-3.webp',
    content: [
      {
        title: 'Ataşehir Kepenk Servisi',
        text: `Ataşehir'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Ataşehir'in tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Ataşehir Kepenk Tamiri',
        text: `Ataşehir'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Ataşehir'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Ataşehir Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Ataşehir'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Ataşehir\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Ataşehir\'in tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Ataşehir\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Ataşehir\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Ataşehir kepenk servisi, Ataşehir kepenk tamiri, Ataşehir otomatik kepenk, Ataşehir kepenk tamircisi, Ataşehir merkez kepenk servisi, Barbaros kepenk tamiri',
    neighborhoods: ['Atatürk', 'Barbaros', 'Ferhatpaşa', 'İçerenköy', 'İnönü', 'Kayışdağı', 'Küçükbakkalköy', 'Yeni Çamlıca']
  },
  'avcilar': {
    title: 'Avcılar Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Avcılar\'da profesyonel otomatik kepenk servisi, tamiri ve bakım hizmetleri. ✓7/24 Acil Servis ✓Uygun Fiyat ✓20 Yıllık Deneyim',
    heroImage: '/assets/service/service-4.webp',
    content: [
      {
        title: 'Avcılar Kepenk Servisi',
        text: `Avcılar'da otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Avcılar'ın tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Avcılar Kepenk Tamiri',
        text: `Avcılar'da her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Avcılar'da kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Avcılar Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Avcılar'da ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Avcılar\'da kepenk servisi ne kadar sürede gelir?',
        answer: 'Avcılar\'ın tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Avcılar\'da hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Avcılar\'da kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Avcılar kepenk servisi, Avcılar kepenk tamiri, Avcılar otomatik kepenk, Avcılar kepenk tamircisi, Ambarlı kepenk servisi, Denizköşkler kepenk tamiri',
    neighborhoods: ['Ambarlı', 'Cihangir', 'Denizköşkler', 'Firuzköy', 'Gümüşpala', 'Merkez', 'Tahtakale', 'Üniversite']
  },
  'bagcilar': {
    title: 'Bağcılar Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Bağcılar\'da profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Bağcılar\'ın güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-1.webp',
    content: [
      {
        title: 'Bağcılar Kepenk Servisi',
        text: `Bağcılar'da otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Bağcılar'ın tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Mahmutbey, Güneşli, Demirkapı başta olmak üzere Bağcılar'ın her noktasında 30 dakika içinde yanınızdayız.

        Bağcılar'da kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Bağcılar'ın en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Bağcılar Kepenk Tamiri',
        text: `Bağcılar'da her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Bağcılar'da kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Bağcılar Sıfır Kepenk',
        text: `Bağcılar'da işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Bağcılar'da yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Bağcılar\'da kepenk servisi ne kadar sürede gelir?',
        answer: 'Bağcılar\'ın tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Bağcılar\'da hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Bağcılar\'da kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Bağcılar kepenk servisi, Bağcılar kepenk tamiri, Bağcılar otomatik kepenk, Bağcılar kepenk tamircisi, Mahmutbey kepenk servisi, Güneşli kepenk tamiri',
    neighborhoods: ['Mahmutbey', 'Güneşli', 'Demirkapı', 'Yenigün', 'Yavuzselim', 'Fatih', 'Bağlar', 'Kirazlı']
  },
  'bahcelievler': {
    title: 'Bahçelievler Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Bahçelievler\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Bahçelievler\'in güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-2.webp',
    content: [
      {
        title: 'Bahçelievler Kepenk Servisi',
        text: `Bahçelievler'de otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Bahçelievler'in tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Yenibosna, Şirinevler, Kocasinan başta olmak üzere Bahçelievler'in her noktasında 30 dakika içinde yanınızdayız.

        Bahçelievler'de kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Bahçelievler'in en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Bahçelievler Kepenk Tamiri',
        text: `Bahçelievler'de her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Bahçelievler'de kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Bahçelievler Sıfır Kepenk',
        text: `Bahçelievler'de işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Bahçelievler'de yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Bahçelievler\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Bahçelievler\'in tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Bahçelievler\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Bahçelievler\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Bahçelievler kepenk servisi, Bahçelievler kepenk tamiri, Bahçelievler otomatik kepenk, Bahçelievler kepenk tamircisi, Yenibosna kepenk servisi, Şirinevler kepenk tamiri',
    neighborhoods: ['Yenibosna', 'Şirinevler', 'Kocasinan', 'Zafer', 'Cumhuriyet', 'Fevzi Çakmak', 'Bahçelievler Merkez', 'Soğanlı']
  },
  'bakirkoy': {
    title: 'Bakırköy Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Bakırköy\'de profesyonel otomatik kepenk servisi, tamiri ve bakım hizmetleri. ✓7/24 Acil Servis ✓Uygun Fiyat ✓20 Yıllık Deneyim',
    heroImage: '/assets/service/service-1.webp',
    content: [
      {
        title: 'Bakırköy Kepenk Servisi',
        text: `Bakırköy'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Bakırköy'ün tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Bakırköy Kepenk Tamiri',
        text: `Bakırköy'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Bakırköy'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Bakırköy Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Bakırköy'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Bakırköy\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Bakırköy\'ün tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Bakırköy\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Bakırköy\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Bakırköy kepenk servisi, Bakırköy kepenk tamiri, Bakırköy otomatik kepenk, Bakırköy kepenk tamircisi, Ataköy kepenk servisi, Yeşilköy kepenk tamiri',
    neighborhoods: ['Ataköy', 'Cevizlik', 'Kartaltepe', 'Osmaniye', 'Sakızağacı', 'Şenlikköy', 'Yeşilköy', 'Zeytinlik']
  },
  'basaksehir': {
    title: 'Başakşehir Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Başakşehir\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Başakşehir\'in güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-3.webp',
    content: [
      {
        title: 'Başakşehir Kepenk Servisi',
        text: `Başakşehir'de otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Başakşehir'in tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Başak, Kayaşehir, İkitelli başta olmak üzere Başakşehir'in her noktasında 30 dakika içinde yanınızdayız.

        Başakşehir'de kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Başakşehir'in en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Başakşehir Kepenk Tamiri',
        text: `Başakşehir'de her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Başakşehir'de kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Başakşehir Sıfır Kepenk',
        text: `Başakşehir'de işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Başakşehir'de yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Başakşehir\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Başakşehir\'in tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Başakşehir\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Başakşehir\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Başakşehir kepenk servisi, Başakşehir kepenk tamiri, Başakşehir otomatik kepenk, Başakşehir kepenk tamircisi, Başak kepenk servisi, Kayaşehir kepenk tamiri',
    neighborhoods: ['Başak', 'Kayaşehir', 'İkitelli', 'Ziya Gökalp', 'Güvercintepe', 'Şahintepe', 'Altınşehir', 'Bahçeşehir']
  },
  'bayrampasa': {
    title: 'Bayrampaşa Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Bayrampaşa\'da profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Bayrampaşa\'nın güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-4.webp',
    content: [
      {
        title: 'Bayrampaşa Kepenk Servisi',
        text: `Bayrampaşa'da otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Bayrampaşa'nın tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Yıldırım, Terazidere, Muratpaşa başta olmak üzere Bayrampaşa'nın her noktasında 30 dakika içinde yanınızdayız.

        Bayrampaşa'da kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Bayrampaşa'nın en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Bayrampaşa Kepenk Tamiri',
        text: `Bayrampaşa'da her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Bayrampaşa'da kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Bayrampaşa Sıfır Kepenk',
        text: `Bayrampaşa'da işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Bayrampaşa'da yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Bayrampaşa\'da kepenk servisi ne kadar sürede gelir?',
        answer: 'Bayrampaşa\'nın tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Bayrampaşa\'da hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Bayrampaşa\'da kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Bayrampaşa kepenk servisi, Bayrampaşa kepenk tamiri, Bayrampaşa otomatik kepenk, Bayrampaşa kepenk tamircisi, Yıldırım kepenk servisi, Terazidere kepenk tamiri',
    neighborhoods: ['Yıldırım', 'Terazidere', 'Muratpaşa', 'İsmetpaşa', 'Kartaltepe', 'Yenidoğan', 'Cevatpaşa', 'Ortamahalle']
  },
  'besiktas': {
    title: 'Beşiktaş Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Beşiktaş\'ta profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Beşiktaş\'ın güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-1.webp',
    content: [
      {
        title: 'Beşiktaş Kepenk Servisi',
        text: `Beşiktaş'ta otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Beşiktaş'ın tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Levent, Etiler, Arnavutköy başta olmak üzere Beşiktaş'ın her noktasında 30 dakika içinde yanınızdayız.

        Beşiktaş'ta kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Beşiktaş'ın en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Beşiktaş Kepenk Tamiri',
        text: `Beşiktaş'ta her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Beşiktaş'ta kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Beşiktaş Sıfır Kepenk',
        text: `Beşiktaş'ta işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Beşiktaş'ta yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Beşiktaş\'ta kepenk servisi ne kadar sürede gelir?',
        answer: 'Beşiktaş\'ın tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Beşiktaş\'ta hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Beşiktaş\'ta kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Beşiktaş kepenk servisi, Beşiktaş kepenk tamiri, Beşiktaş otomatik kepenk, Beşiktaş kepenk tamircisi, Levent kepenk servisi, Etiler kepenk tamiri',
    neighborhoods: ['Levent', 'Etiler', 'Arnavutköy', 'Bebek', 'Ortaköy', 'Ulus', 'Gayrettepe', 'Türkali']
  },
  'beykoz': {
    title: 'Beykoz Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Beykoz\'da profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Beykoz\'un güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-2.webp',
    content: [
      {
        title: 'Beykoz Kepenk Servisi',
        text: `Beykoz'da otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Beykoz'un tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Paşabahçe, Anadolu Hisarı, Kavacık başta olmak üzere Beykoz'un her noktasında 30 dakika içinde yanınızdayız.

        Beykoz'da kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Beykoz'un en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Beykoz Kepenk Tamiri',
        text: `Beykoz'da her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Beykoz'da kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Beykoz Sıfır Kepenk',
        text: `Beykoz'da işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Beykoz'da yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Beykoz\'da kepenk servisi ne kadar sürede gelir?',
        answer: 'Beykoz\'un tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Beykoz\'da hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Beykoz\'da kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Beykoz kepenk servisi, Beykoz kepenk tamiri, Beykoz otomatik kepenk, Beykoz kepenk tamircisi, Paşabahçe kepenk servisi, Kavacık kepenk tamiri',
    neighborhoods: ['Paşabahçe', 'Anadolu Hisarı', 'Kavacık', 'Çubuklu', 'Göksu', 'Yalıköy', 'Acarlar', 'Rüzgarlıbahçe']
  },
  'beylikduzu': {
    title: 'Beylikdüzü Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Beylikdüzü\'nde profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Beylikdüzü\'nün güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-3.webp',
    content: [
      {
        title: 'Beylikdüzü Kepenk Servisi',
        text: `Beylikdüzü'nde otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Beylikdüzü'nün tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Büyükşehir, Barış, Adnan Kahveci başta olmak üzere Beylikdüzü'nün her noktasında 30 dakika içinde yanınızdayız.

        Beylikdüzü'nde kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Beylikdüzü'nün en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Beylikdüzü Kepenk Tamiri',
        text: `Beylikdüzü'nde her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Beylikdüzü'nde kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Beylikdüzü Sıfır Kepenk',
        text: `Beylikdüzü'nde işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Beylikdüzü'nde yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Beylikdüzü\'nde kepenk servisi ne kadar sürede gelir?',
        answer: 'Beylikdüzü\'nün tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Beylikdüzü\'nde hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Beylikdüzü\'nde kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Beylikdüzü kepenk servisi, Beylikdüzü kepenk tamiri, Beylikdüzü otomatik kepenk, Beylikdüzü kepenk tamircisi, Büyükşehir kepenk servisi, Barış kepenk tamiri',
    neighborhoods: ['Büyükşehir', 'Barış', 'Adnan Kahveci', 'Cumhuriyet', 'Yakuplu', 'Marmara', 'Sahil', 'Kavaklı']
  },
  'beyoglu': {
    title: 'Beyoğlu Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Beyoğlu\'nda profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Beyoğlu\'nun güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-4.webp',
    content: [
      {
        title: 'Beyoğlu Kepenk Servisi',
        text: `Beyoğlu'nda otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Beyoğlu'nun tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Taksim, Galata, Cihangir başta olmak üzere Beyoğlu'nun her noktasında 30 dakika içinde yanınızdayız.

        Beyoğlu'nda kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Beyoğlu'nun en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Beyoğlu Kepenk Tamiri',
        text: `Beyoğlu'nda her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Beyoğlu'nda kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Beyoğlu Sıfır Kepenk',
        text: `Beyoğlu'nda işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Beyoğlu'nda yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Beyoğlu\'nda kepenk servisi ne kadar sürede gelir?',
        answer: 'Beyoğlu\'nun tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Beyoğlu\'nda hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Beyoğlu\'nda kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Beyoğlu kepenk servisi, Beyoğlu kepenk tamiri, Beyoğlu otomatik kepenk, Beyoğlu kepenk tamircisi, Taksim kepenk servisi, Galata kepenk tamiri',
    neighborhoods: ['Taksim', 'Galata', 'Cihangir', 'Karaköy', 'Tarlabaşı', 'Kasımpaşa', 'Şişhane', 'İstiklal']
  },
  'buyukcekmece': {
    title: 'Büyükçekmece Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Büyükçekmece\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Büyükçekmece\'nin güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-1.webp',
    content: [
      {
        title: 'Büyükçekmece Kepenk Servisi',
        text: `Büyükçekmece'de otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Büyükçekmece'nin tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Mimarsinan, Fatih, Atatürk başta olmak üzere Büyükçekmece'nin her noktasında 30 dakika içinde yanınızdayız.

        Büyükçekmece'de kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Büyükçekmece'nin en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Büyükçekmece Kepenk Tamiri',
        text: `Büyükçekmece'de her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Büyükçekmece'de kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Büyükçekmece Sıfır Kepenk',
        text: `Büyükçekmece'de işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Büyükçekmece'de yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Büyükçekmece\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Büyükçekmece\'nin tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Büyükçekmece\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Büyükçekmece\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Büyükçekmece kepenk servisi, Büyükçekmece kepenk tamiri, Büyükçekmece otomatik kepenk, Büyükçekmece kepenk tamircisi, Mimarsinan kepenk servisi, Fatih kepenk tamiri',
    neighborhoods: ['Mimarsinan', 'Fatih', 'Atatürk', 'Dizdariye', 'Kumburgaz', 'Celaliye', 'Güzelce', 'Sinanoba']
  },
  'catalca': {
    title: 'Çatalca Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Çatalca\'da profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Çatalca\'nın güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-2.webp',
    content: [
      {
        title: 'Çatalca Kepenk Servisi',
        text: `Çatalca'da otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Çatalca'nın tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Kaleiçi, Ferhatpaşa, İzzettin başta olmak üzere Çatalca'nın her noktasında 30 dakika içinde yanınızdayız.

        Çatalca'da kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Çatalca'nın en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Çatalca Kepenk Tamiri',
        text: `Çatalca'da her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Çatalca'da kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Çatalca Sıfır Kepenk',
        text: `Çatalca'da işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Çatalca'da yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Çatalca\'da kepenk servisi ne kadar sürede gelir?',
        answer: 'Çatalca\'nın tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Çatalca\'da hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Çatalca\'da kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Çatalca kepenk servisi, Çatalca kepenk tamiri, Çatalca otomatik kepenk, Çatalca kepenk tamircisi, Kaleiçi kepenk servisi, Ferhatpaşa kepenk tamiri',
    neighborhoods: ['Kaleiçi', 'Ferhatpaşa', 'İzzettin', 'Bahşayış', 'Çakıl', 'Gökçeali', 'Karacaköy', 'Ormanlı']
  },
  'cekmekoy': {
    title: 'Çekmeköy Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Çekmeköy\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Çekmeköy\'ün güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-3.webp',
    content: [
      {
        title: 'Çekmeköy Kepenk Servisi',
        text: `Çekmeköy'de otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Çekmeköy'ün tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Merkez, Hamidiye, Mehmet Akif başta olmak üzere Çekmeköy'ün her noktasında 30 dakika içinde yanınızdayız.

        Çekmeköy'de kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Çekmeköy'ün en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Çekmeköy Kepenk Tamiri',
        text: `Çekmeköy'de her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Çekmeköy'de kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Çekmeköy Sıfır Kepenk',
        text: `Çekmeköy'de işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Çekmeköy'de yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Çekmeköy\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Çekmeköy\'ün tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Çekmeköy\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Çekmeköy\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Çekmeköy kepenk servisi, Çekmeköy kepenk tamiri, Çekmeköy otomatik kepenk, Çekmeköy kepenk tamircisi, Merkez kepenk servisi, Hamidiye kepenk tamiri',
    neighborhoods: ['Merkez', 'Hamidiye', 'Mehmet Akif', 'Mimar Sinan', 'Çamlık', 'Ekşioğlu', 'Cumhuriyet', 'Aydınlar']
  },
  'esenler': {
    title: 'Esenler Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Esenler\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Esenler\'in güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-4.webp',
    content: [
      {
        title: 'Esenler Kepenk Servisi',
        text: `Esenler'de otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Esenler'in tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Dörtyol, Fatih, Tuna başta olmak üzere Esenler'in her noktasında 30 dakika içinde yanınızdayız.

        Esenler'de kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Esenler'in en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Esenler Kepenk Tamiri',
        text: `Esenler'de her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Esenler'de kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Esenler Sıfır Kepenk',
        text: `Esenler'de işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Esenler'de yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Esenler\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Esenler\'in tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Esenler\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Esenler\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Esenler kepenk servisi, Esenler kepenk tamiri, Esenler otomatik kepenk, Esenler kepenk tamircisi, Dörtyol kepenk servisi, Fatih kepenk tamiri',
    neighborhoods: ['Dörtyol', 'Fatih', 'Tuna', 'Birlik', 'Çiftehavuzlar', 'Davutpaşa', 'Havaalanı', 'Menderes']
  },
  'esenyurt': {
    title: 'Esenyurt Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Esenyurt\'ta profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Esenyurt\'un güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-1.webp',
    content: [
      {
        title: 'Esenyurt Kepenk Servisi',
        text: `Esenyurt'ta otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Esenyurt'un tüm mahallelerinde kepenk servisi hizmeti veriyoruz. İnönü, Cumhuriyet, Mehterçeşme başta olmak üzere Esenyurt'un her noktasında 30 dakika içinde yanınızdayız.

        Esenyurt'ta kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Esenyurt'un en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Esenyurt Kepenk Tamiri',
        text: `Esenyurt'ta her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Esenyurt'ta kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Esenyurt Sıfır Kepenk',
        text: `Esenyurt'ta işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Esenyurt'ta yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Esenyurt\'ta kepenk servisi ne kadar sürede gelir?',
        answer: 'Esenyurt\'un tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Esenyurt\'ta hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Esenyurt\'ta kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Esenyurt kepenk servisi, Esenyurt kepenk tamiri, Esenyurt otomatik kepenk, Esenyurt kepenk tamircisi, İnönü kepenk servisi, Cumhuriyet kepenk tamiri',
    neighborhoods: ['İnönü', 'Cumhuriyet', 'Mehterçeşme', 'Pınar', 'Saadetdere', 'Talatpaşa', 'Yenikent', 'Zafer']
  },
  'eyup': {
    title: 'Eyüp Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Eyüp\'te profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Eyüp\'ün güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-2.webp',
    content: [
      {
        title: 'Eyüp Kepenk Servisi',
        text: `Eyüp'te otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Eyüp'ün tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Alibeyköy, Göktürk, Kemerburgaz başta olmak üzere Eyüp'ün her noktasında 30 dakika içinde yanınızdayız.

        Eyüp'te kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Eyüp'ün en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Eyüp Kepenk Tamiri',
        text: `Eyüp'te her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Eyüp'te kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Eyüp Sıfır Kepenk',
        text: `Eyüp'te işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Eyüp'te yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Eyüp\'te kepenk servisi ne kadar sürede gelir?',
        answer: 'Eyüp\'ün tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Eyüp\'te hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Eyüp\'te kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Eyüp kepenk servisi, Eyüp kepenk tamiri, Eyüp otomatik kepenk, Eyüp kepenk tamircisi, Alibeyköy kepenk servisi, Göktürk kepenk tamiri',
    neighborhoods: ['Alibeyköy', 'Göktürk', 'Kemerburgaz', 'Yeşilpınar', 'Akşemsettin', 'Rami', 'Güzeltepe', 'Karadolap']
  },
  'fatih': {
    title: 'Fatih Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Fatih\'te profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Fatih\'in güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-3.webp',
    content: [
      {
        title: 'Fatih Kepenk Servisi',
        text: `Fatih'te otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Fatih'in tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Aksaray, Çapa, Fındıkzade başta olmak üzere Fatih'in her noktasında 30 dakika içinde yanınızdayız.

        Fatih'te kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Fatih'in en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Fatih Kepenk Tamiri',
        text: `Fatih'te her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Fatih'te kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Fatih Sıfır Kepenk',
        text: `Fatih'te işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Fatih'te yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Fatih\'te kepenk servisi ne kadar sürede gelir?',
        answer: 'Fatih\'in tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Fatih\'te hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Fatih\'te kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Fatih kepenk servisi, Fatih kepenk tamiri, Fatih otomatik kepenk, Fatih kepenk tamircisi, Aksaray kepenk servisi, Çapa kepenk tamiri',
    neighborhoods: ['Aksaray', 'Çapa', 'Fındıkzade', 'Karagümrük', 'Vefa', 'Zeyrek', 'Balat', 'Sultanahmet']
  },
  'gaziosmanpasa': {
    title: 'Gaziosmanpaşa Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Gaziosmanpaşa\'da profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Gaziosmanpaşa\'nın güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-4.webp',
    content: [
      {
        title: 'Gaziosmanpaşa Kepenk Servisi',
        text: `Gaziosmanpaşa'da otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Gaziosmanpaşa'nın tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Merkez, Karadeniz, Barbaros başta olmak üzere Gaziosmanpaşa'nın her noktasında 30 dakika içinde yanınızdayız.

        Gaziosmanpaşa'da kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Gaziosmanpaşa'nın en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Gaziosmanpaşa Kepenk Tamiri',
        text: `Gaziosmanpaşa'da her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Gaziosmanpaşa'da kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Gaziosmanpaşa Sıfır Kepenk',
        text: `Gaziosmanpaşa'da işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Gaziosmanpaşa'da yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Gaziosmanpaşa\'da kepenk servisi ne kadar sürede gelir?',
        answer: 'Gaziosmanpaşa\'nın tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Gaziosmanpaşa\'da hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Gaziosmanpaşa\'da kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Gaziosmanpaşa kepenk servisi, Gaziosmanpaşa kepenk tamiri, Gaziosmanpaşa otomatik kepenk, Gaziosmanpaşa kepenk tamircisi, Merkez kepenk servisi, Karadeniz kepenk tamiri',
    neighborhoods: ['Merkez', 'Karadeniz', 'Barbaros', 'Yıldıztabya', 'Mevlana', 'Pazariçi', 'Sarıgöl', 'Yenimahalle']
  },
  'gungoren': {
    title: 'Güngören Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Güngören\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Güngören\'in güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-1.webp',
    content: [
      {
        title: 'Güngören Kepenk Servisi',
        text: `Güngören'de otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Güngören'in tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Merkez, Tozkoparan, Güneştepe başta olmak üzere Güngören'in her noktasında 30 dakika içinde yanınızdayız.

        Güngören'de kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Güngören'in en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Güngören Kepenk Tamiri',
        text: `Güngören'de her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Güngören'de kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Güngören Sıfır Kepenk',
        text: `Güngören'de işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Güngören'de yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Güngören\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Güngören\'in tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Güngören\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Güngören\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Güngören kepenk servisi, Güngören kepenk tamiri, Güngören otomatik kepenk, Güngören kepenk tamircisi, Merkez kepenk servisi, Tozkoparan kepenk tamiri',
    neighborhoods: ['Merkez', 'Tozkoparan', 'Güneştepe', 'Akıncılar', 'Gençosman', 'Haznedar', 'Mehmet Nezih Özmen', 'Sanayi']
  },
  'kadikoy': {
    title: 'Kadıköy Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Kadıköy\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Kadıköy\'ün güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-2.webp',
    content: [
      {
        title: 'Kadıköy Kepenk Servisi',
        text: `Kadıköy'de otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Kadıköy'ün tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Bostancı, Caddebostan, Feneryolu başta olmak üzere Kadıköy'ün her noktasında 30 dakika içinde yanınızdayız.

        Kadıköy'de kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Kadıköy'ün en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Kadıköy Kepenk Tamiri',
        text: `Kadıköy'de her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Kadıköy'de kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Kadıköy Sıfır Kepenk',
        text: `Kadıköy'de işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Kadıköy'de yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Kadıköy\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Kadıköy\'ün tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Kadıköy\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Kadıköy\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Kadıköy kepenk servisi, Kadıköy kepenk tamiri, Kadıköy otomatik kepenk, Kadıköy kepenk tamircisi, Bostancı kepenk servisi, Caddebostan kepenk tamiri',
    neighborhoods: ['Bostancı', 'Caddebostan', 'Feneryolu', 'Göztepe', 'Kozyatağı', 'Suadiye', 'Erenköy', 'Fikirtepe']
  },
  'kagithane': {
    title: 'Kağıthane Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Kağıthane\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Kağıthane\'nin güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-3.webp',
    content: [
      {
        title: 'Kağıthane Kepenk Servisi',
        text: `Kağıthane'de otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Kağıthane'nin tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Çağlayan, Gültepe, Harmantepe başta olmak üzere Kağıthane'nin her noktasında 30 dakika içinde yanınızdayız.

        Kağıthane'de kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Kağıthane'nin en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Kağıthane Kepenk Tamiri',
        text: `Kağıthane'de her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Kağıthane'de kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Kağıthane Sıfır Kepenk',
        text: `Kağıthane'de işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Kağıthane'de yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Kağıthane\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Kağıthane\'nin tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Kağıthane\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Kağıthane\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Kağıthane kepenk servisi, Kağıthane kepenk tamiri, Kağıthane otomatik kepenk, Kağıthane kepenk tamircisi, Çağlayan kepenk servisi, Gültepe kepenk tamiri',
    neighborhoods: ['Çağlayan', 'Gültepe', 'Harmantepe', 'Merkez', 'Nurtepe', 'Ortabayır', 'Seyrantepe', 'Talatpaşa']
  },
  'kartal': {
    title: 'Kartal Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Kartal\'da profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Kartal\'ın güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-4.webp',
    content: [
      {
        title: 'Kartal Kepenk Servisi',
        text: `Kartal'da otomatik kepenk servisi ve tamiri için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimizle Kartal'ın tüm mahallelerinde kepenk servisi hizmeti veriyoruz. Kordonboyu, Petroliş, Yakacık başta olmak üzere Kartal'ın her noktasında 30 dakika içinde yanınızdayız.

        Kartal'da kepenk servisi ihtiyaçlarınız için 7/24 hizmetinizdeyiz. Kepenk motorunuz mu arızalandı? Kumandanız mı çalışmıyor? Kepenk raydan mı çıktı? Tüm bu sorunlar için Kartal'ın en hızlı ve güvenilir kepenk servisi olarak yanınızdayız.`
      },
      {
        title: 'Kartal Kepenk Tamiri',
        text: `Kartal'da her marka ve model otomatik kepenk tamiri yapıyoruz. Kepenk tamiri konusunda uzman ekibimiz, en karmaşık arızaları bile aynı gün çözüme kavuşturuyor. Kartal'da kepenk tamiri için bizi tercih eden işletmelere kaliteli ve uygun fiyatlı hizmet sunuyoruz.

        • Kepenk Motor Tamiri
        • Elektronik Kart Tamiri
        • Kumanda Programlama
        • Yay Sistemi Tamiri
        • Ray Tamiri ve Bakımı
        • Şaft ve Dişli Tamiri`
      },
      {
        title: 'Kartal Sıfır Kepenk',
        text: `Kartal'da işyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. İhtiyacınıza özel tasarlanan otomatik kepenk sistemlerimiz, yüksek kalite ve uygun fiyat avantajı sunar. Ücretsiz keşif hizmetimizle Kartal'da yerinize gelip, en uygun kepenk sistemini belirliyor ve montajını yapıyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Kartal\'da kepenk servisi ne kadar sürede gelir?',
        answer: 'Kartal\'ın tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Kartal\'da hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Kartal\'da kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Kartal kepenk servisi, Kartal kepenk tamiri, Kartal otomatik kepenk, Kartal kepenk tamircisi, Kordonboyu kepenk servisi, Yakacık kepenk tamiri',
    neighborhoods: ['Kordonboyu', 'Petroliş', 'Yakacık', 'Atalar', 'Cevizli', 'Esentepe', 'Karlıktepe', 'Uğurmumcu']
  },
  'kucukcekmece': {
    title: 'Küçükçekmece Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Küçükçekmece\'de profesyonel otomatik kepenk servisi, tamiri ve bakım hizmetleri. ✓7/24 Acil Servis ✓Uygun Fiyat ✓20 Yıllık Deneyim',
    heroImage: '/assets/service/service-2.webp',
    content: [
      {
        title: 'Küçükçekmece Kepenk Servisi',
        text: `Küçükçekmece'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Küçükçekmece'nin tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Küçükçekmece Kepenk Tamiri',
        text: `Küçükçekmece'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Küçükçekmece'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Küçükçekmece Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Küçükçekmece'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Küçükçekmece\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Küçükçekmece\'nin tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Küçükçekmece\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Küçükçekmece\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Küçükçekmece kepenk servisi, Küçükçekmece kepenk tamiri, Küçükçekmece otomatik kepenk, Küçükçekmece kepenk tamircisi, Sefaköy kepenk servisi, Halkalı kepenk tamiri',
    neighborhoods: ['Atakent', 'Cennet', 'Fatih', 'Halkalı', 'İnönü', 'Sefaköy', 'Tevfikbey', 'Yarımburgaz']
  },
  'maltepe': {
    title: 'Maltepe Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Maltepe\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Maltepe\'nin güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-3.webp',
    content: [
      {
        title: 'Maltepe Kepenk Servisi',
        text: `Maltepe'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Maltepe'nin tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Maltepe Kepenk Tamiri',
        text: `Maltepe'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Maltepe'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Maltepe Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Maltepe'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Maltepe\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Maltepe\'nin tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Maltepe\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Maltepe\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Maltepe kepenk servisi, Maltepe kepenk tamiri, Maltepe otomatik kepenk, Maltepe kepenk tamircisi, Cevizli kepenk servisi, Fındıklı kepenk tamiri',
    neighborhoods: ['Altayçeşme', 'Bağlarbaşı', 'Cevizli', 'Fındıklı', 'Gülsuyu', 'İdealtepe', 'Küçükyalı', 'Zümrütevler']
  },
  'pendik': {
    title: 'Pendik Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Pendik\'te profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Pendik\'in güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-4.webp',
    content: [
      {
        title: 'Pendik Kepenk Servisi',
        text: `Pendik'te otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Pendik'in tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Pendik Kepenk Tamiri',
        text: `Pendik'te her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Pendik'te kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Pendik Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Pendik'te ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Pendik\'te kepenk servisi ne kadar sürede gelir?',
        answer: 'Pendik\'in tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Pendik\'te hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Pendik\'te kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Pendik kepenk servisi, Pendik kepenk tamiri, Pendik otomatik kepenk, Pendik kepenk tamircisi, Kurtköy kepenk servisi, Velibaba kepenk tamiri',
    neighborhoods: ['Kurtköy', 'Velibaba', 'Yenişehir', 'Çamlık', 'Batı', 'Doğu', 'Güzelyalı', 'Kaynarca']
  },
  'sancaktepe': {
    title: 'Sancaktepe Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Sancaktepe\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Sancaktepe\'nin güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-1.webp',
    content: [
      {
        title: 'Sancaktepe Kepenk Servisi',
        text: `Sancaktepe'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Sancaktepe'nin tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Sancaktepe Kepenk Tamiri',
        text: `Sancaktepe'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Sancaktepe'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Sancaktepe Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Sancaktepe'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Sancaktepe\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Sancaktepe\'nin tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Sancaktepe\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Sancaktepe\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Sancaktepe kepenk servisi, Sancaktepe kepenk tamiri, Sancaktepe otomatik kepenk, Sancaktepe kepenk tamircisi, Samandıra kepenk servisi, Sarıgazi kepenk tamiri',
    neighborhoods: ['Samandıra', 'Sarıgazi', 'Yenidoğan', 'Abdurrahmangazi', 'Akpınar', 'Emek', 'Meclis', 'Osmangazi']
  },
  'sariyer': {
    title: 'Sarıyer Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Sarıyer\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Sarıyer\'in güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-2.webp',
    content: [
      {
        title: 'Sarıyer Kepenk Servisi',
        text: `Sarıyer'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Sarıyer'in tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Sarıyer Kepenk Tamiri',
        text: `Sarıyer'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Sarıyer'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Sarıyer Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Sarıyer'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Sarıyer\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Sarıyer\'in tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Sarıyer\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Sarıyer\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Sarıyer kepenk servisi, Sarıyer kepenk tamiri, Sarıyer otomatik kepenk, Sarıyer kepenk tamircisi, Maslak kepenk servisi, İstinye kepenk tamiri',
    neighborhoods: ['Maslak', 'İstinye', 'Tarabya', 'Yeniköy', 'Emirgan', 'Rumelihisarı', 'Baltalimanı', 'Büyükdere']
  },
  'silivri': {
    title: 'Silivri Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Silivri\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Silivri\'nin güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-3.webp',
    content: [
      {
        title: 'Silivri Kepenk Servisi',
        text: `Silivri'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Silivri'nin tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Silivri Kepenk Tamiri',
        text: `Silivri'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Silivri'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Silivri Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Silivri'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Silivri\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Silivri\'nin tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Silivri\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Silivri\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Silivri kepenk servisi, Silivri kepenk tamiri, Silivri otomatik kepenk, Silivri kepenk tamircisi, Selimpaşa kepenk servisi, Değirmenköy kepenk tamiri',
    neighborhoods: ['Selimpaşa', 'Değirmenköy', 'Ortaköy', 'Alipaşa', 'Piri Mehmet Paşa', 'Mimarsinan', 'Fatih', 'Cumhuriyet']
  },
  'sultanbeyli': {
    title: 'Sultanbeyli Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Sultanbeyli\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Sultanbeyli\'nin güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-4.webp',
    content: [
      {
        title: 'Sultanbeyli Kepenk Servisi',
        text: `Sultanbeyli'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Sultanbeyli'nin tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Sultanbeyli Kepenk Tamiri',
        text: `Sultanbeyli'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Sultanbeyli'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Sultanbeyli Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Sultanbeyli'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Sultanbeyli\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Sultanbeyli\'nin tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Sultanbeyli\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Sultanbeyli\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Sultanbeyli kepenk servisi, Sultanbeyli kepenk tamiri, Sultanbeyli otomatik kepenk, Sultanbeyli kepenk tamircisi, Mecidiye kepenk servisi, Abdurrahman Gazi kepenk tamiri',
    neighborhoods: ['Mecidiye', 'Abdurrahman Gazi', 'Battalgazi', 'Fatih', 'Hamidiye', 'Mehmet Akif', 'Orhangazi', 'Yavuz Selim']
  },
  'sultangazi': {
    title: 'Sultangazi Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Sultangazi\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Sultangazi\'nin güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-1.webp',
    content: [
      {
        title: 'Sultangazi Kepenk Servisi',
        text: `Sultangazi'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Sultangazi'nin tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Sultangazi Kepenk Tamiri',
        text: `Sultangazi'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Sultangazi'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Sultangazi Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Sultangazi'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Sultangazi\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Sultangazi\'nin tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Sultangazi\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Sultangazi\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Sultangazi kepenk servisi, Sultangazi kepenk tamiri, Sultangazi otomatik kepenk, Sultangazi kepenk tamircisi, Cebeci kepenk servisi, Esentepe kepenk tamiri',
    neighborhoods: ['Cebeci', 'Esentepe', 'Gazi', 'İsmetpaşa', 'Malkoçoğlu', 'Sultançiftliği', 'Uğurmumcu', 'Yunus Emre']
  },
  'sile': {
    title: 'Şile Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Şile\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Şile\'nin güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-2.webp',
    content: [
      {
        title: 'Şile Kepenk Servisi',
        text: `Şile'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Şile'nin tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Şile Kepenk Tamiri',
        text: `Şile'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Şile'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Şile Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Şile'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Şile\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Şile\'nin tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Şile\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Şile\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Şile kepenk servisi, Şile kepenk tamiri, Şile otomatik kepenk, Şile kepenk tamircisi, Ağva kepenk servisi, Merkez kepenk tamiri',
    neighborhoods: ['Ağva', 'Merkez', 'Ahmetli', 'Alacalı', 'Balibey', 'Çayırbaşı', 'Doğancılı', 'İmrenli']
  },
  'sisli': {
    title: 'Şişli Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Şişli\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Şişli\'nin güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-3.webp',
    content: [
      {
        title: 'Şişli Kepenk Servisi',
        text: `Şişli'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Şişli'nin tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Şişli Kepenk Tamiri',
        text: `Şişli'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Şişli'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Şişli Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Şişli'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Şişli\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Şişli\'nin tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Şişli\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Şişli\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Şişli kepenk servisi, Şişli kepenk tamiri, Şişli otomatik kepenk, Şişli kepenk tamircisi, Mecidiyeköy kepenk servisi, Nişantaşı kepenk tamiri',
    neighborhoods: ['Mecidiyeköy', 'Nişantaşı', 'Fulya', 'Halaskargazi', 'Harbiye', 'Kuştepe', 'Mahmut Şevket Paşa', 'Teşvikiye']
  },
  'tuzla': {
    title: 'Tuzla Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Tuzla\'da profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Tuzla\'nın güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-4.webp',
    content: [
      {
        title: 'Tuzla Kepenk Servisi',
        text: `Tuzla'da otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Tuzla'nın tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Tuzla Kepenk Tamiri',
        text: `Tuzla'da her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Tuzla'da kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Tuzla Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Tuzla'da ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Tuzla\'da kepenk servisi ne kadar sürede gelir?',
        answer: 'Tuzla\'nın tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Tuzla\'da hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Tuzla\'da kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Tuzla kepenk servisi, Tuzla kepenk tamiri, Tuzla otomatik kepenk, Tuzla kepenk tamircisi, İçmeler kepenk servisi, Aydınlı kepenk tamiri',
    neighborhoods: ['İçmeler', 'Aydınlı', 'Postane', 'Mimar Sinan', 'Yayla', 'Tepeören', 'Akfırat', 'Orhanlı']
  },
  'umraniye': {
    title: 'Ümraniye Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Ümraniye\'de profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Ümraniye\'nin güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-1.webp',
    content: [
      {
        title: 'Ümraniye Kepenk Servisi',
        text: `Ümraniye'de otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Ümraniye'nin tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Ümraniye Kepenk Tamiri',
        text: `Ümraniye'de her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Ümraniye'de kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Ümraniye Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Ümraniye'de ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Ümraniye\'de kepenk servisi ne kadar sürede gelir?',
        answer: 'Ümraniye\'nin tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Ümraniye\'de hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Ümraniye\'de kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Ümraniye kepenk servisi, Ümraniye kepenk tamiri, Ümraniye otomatik kepenk, Ümraniye kepenk tamircisi, Çakmak kepenk servisi, Atakent kepenk tamiri',
    neighborhoods: ['Çakmak', 'Atakent', 'Ihlamurkuyu', 'İstiklal', 'Namık Kemal', 'Site', 'Tantavi', 'Tepeüstü']
  },
  'uskudar': {
    title: 'Üsküdar Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Üsküdar\'da profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Üsküdar\'ın güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-2.webp',
    content: [
      {
        title: 'Üsküdar Kepenk Servisi',
        text: `Üsküdar'da otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Üsküdar'ın tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Üsküdar Kepenk Tamiri',
        text: `Üsküdar'da her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Üsküdar'da kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Üsküdar Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Üsküdar'da ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Üsküdar\'da kepenk servisi ne kadar sürede gelir?',
        answer: 'Üsküdar\'ın tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Üsküdar\'da hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Üsküdar\'da kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Üsküdar kepenk servisi, Üsküdar kepenk tamiri, Üsküdar otomatik kepenk, Üsküdar kepenk tamircisi, Altunizade kepenk servisi, Beylerbeyi kepenk tamiri',
    neighborhoods: ['Altunizade', 'Beylerbeyi', 'Çengelköy', 'Kısıklı', 'Kuzguncuk', 'Selimiye', 'Validei Atik', 'Zeynep Kamil']
  },
  'zeytinburnu': {
    title: 'Zeytinburnu Otomatik Kepenk Servisi | 7/24 Kepenk Tamiri',
    description: 'Zeytinburnu\'nda profesyonel otomatik kepenk servisi ve tamiri. ✓7/24 Acil Servis ✓Aynı Gün Hizmet ✓Uygun Fiyat. Zeytinburnu\'nun güvenilir kepenk servisi.',
    heroImage: '/assets/service/service-3.webp',
    content: [
      {
        title: 'Zeytinburnu Kepenk Servisi',
        text: `Zeytinburnu'nda otomatik kepenk servisi ihtiyaçlarınız için profesyonel çözümler sunuyoruz. 20 yıllık deneyimimiz ve uzman ekibimizle, kepenk sistemlerinizin sorunsuz çalışmasını sağlıyoruz.

        7/24 acil kepenk servisi hizmetimizle Zeytinburnu'nun tüm mahallelerinde yanınızdayız. Periyodik bakım ve onarım hizmetlerimizle işyerinizin güvenliğini garanti altına alıyoruz.`
      },
      {
        title: 'Zeytinburnu Kepenk Tamiri',
        text: `Zeytinburnu'nda her marka ve model otomatik kepenk sistemleri için profesyonel tamir hizmeti veriyoruz. Motor arızası, elektronik kart sorunu, kumanda problemi gibi tüm kepenk arızalarına kalıcı çözümler üretiyoruz.

        Deneyimli ekibimiz ve orijinal yedek parça garantimizle kepenk tamiri hizmetinizi aynı gün tamamlıyoruz. Uygun fiyat ve kaliteli hizmet anlayışımızla Zeytinburnu'nda kepenk tamiri için ilk tercih olmaya devam ediyoruz.`
      },
      {
        title: 'Zeytinburnu Sıfır Kepenk',
        text: `İşyeriniz için modern ve güvenilir sıfır kepenk sistemleri sunuyoruz. Zeytinburnu'nda ücretsiz keşif hizmetimizle ihtiyacınıza en uygun kepenk sistemini belirliyor ve profesyonel montaj hizmeti veriyoruz.

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Paslanmaz Çelik Kepenk`
      }
    ],
    faqs: [
      {
        question: 'Zeytinburnu\'nda kepenk servisi ne kadar sürede gelir?',
        answer: 'Zeytinburnu\'nun tüm mahallelerine ortalama 30 dakika içinde ulaşıyoruz. Acil durumlarda en hızlı şekilde yanınızda oluyoruz.'
      },
      {
        question: 'Zeytinburnu\'nda hangi marka kepenkler için servis veriyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came başta olmak üzere tüm marka otomatik kepenkler için servis hizmeti veriyoruz.'
      },
      {
        question: 'Zeytinburnu\'nda kepenk tamiri fiyatları nasıl belirlenir?',
        answer: 'Kepenk tamiri fiyatları arızanın türüne ve kullanılacak parçalara göre değişir. Yerinde ücretsiz kontrol sonrası net fiyat bilgisi veriyoruz.'
      }
    ],
    metaKeywords: 'Zeytinburnu kepenk servisi, Zeytinburnu kepenk tamiri, Zeytinburnu otomatik kepenk, Zeytinburnu kepenk tamircisi, Merkezefendi kepenk servisi, Seyitnizam kepenk tamiri',
    neighborhoods: ['Merkezefendi', 'Seyitnizam', 'Beştelsiz', 'Çırpıcı', 'Gökalp', 'Nuripaşa', 'Sümer', 'Telsiz']
  }
};