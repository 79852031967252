import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { contactConfig } from '../config/contactConfig';

const Layout = ({ children, title, description, keywords }) => {
  const defaultTitle = 'Olgun Kepenk - Kaliteli ve Güvenilir Kepenk Sistemleri';
  const defaultDescription =
    'İstanbul\'da kepenk servisi, kepenk tamiri, otomatik kepenk sistemleri ve sıfır kepenk hizmetleri. 7/24 profesyonel kepenk çözümleri.';
  const defaultKeywords =
    'kepenk servisi, kepenk tamiri, sıfır kepenk, kepenk, otomatik kepenk servisi, otomatik kepenk tamiri, istanbul kepenk';

  const handlePhoneClick = () => {
    // Google Ads dönüşüm izleme - Telefon araması
    window.gtag('event', 'conversion', {
      'send_to': 'AW-11534458953/phone_call',
      'event_callback': () => {}
    });
  };

  const handleWhatsAppClick = () => {
    // Google Ads dönüşüm izleme - WhatsApp mesajı
    window.gtag('event', 'conversion', {
      'send_to': 'AW-11534458953/7gwOCIPK6ooaEMnAh_wq',
      'event_callback': () => {}
    });
  };

  return (
    <>
      <Helmet>
        <title>{title || defaultTitle}</title>
        <meta name="description" content={description || defaultDescription} />
        <meta name="keywords" content={keywords || defaultKeywords} />
        <meta property="og:title" content={title || defaultTitle} />
        <meta
          property="og:description"
          content={description || defaultDescription}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.olgunkepenk.com" />
        <link rel="canonical" href="https://www.olgunkepenk.com" />
        <meta name="robots" content="index, follow" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <html lang="tr" />
        <style>
          {`
            @keyframes pulse {
              0% {
                transform: scale(1);
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
              }
              
              70% {
                transform: scale(1.05);
                box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
              }
              
              100% {
                transform: scale(1);
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              }
            }
            
            .pulse {
              animation: pulse 2s infinite;
            }
          `}
        </style>
      </Helmet>
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className="flex-grow pt-20">
          {children}
        </main>
        <Footer />
        
        {/* Sabit Pozisyonlu Butonlar */}
        <div className="fixed left-4 top-3/4 -mt-[45px] z-50 flex flex-col gap-4 md:hidden">
          <a
            href={`tel:${contactConfig.phone}`}
            onClick={handlePhoneClick}
            className="w-16 h-16 flex items-center justify-center rounded-full bg-[#00c1c1] text-white shadow-lg hover:bg-[#00a1a1] transition-colors pulse"
            aria-label="Bizi Arayın"
          >
            <FontAwesomeIcon icon={faPhone} size="2x" />
          </a>
        </div>
        <div className="fixed right-4 top-3/4 -mt-[45px] z-50 flex flex-col gap-4 md:hidden">
          <a
            href={`https://wa.me/${contactConfig.whatsapp}?text=${encodeURIComponent(contactConfig.whatsappMessage)}`}
            onClick={handleWhatsAppClick}
            target="_blank"
            rel="noopener noreferrer"
            className="w-16 h-16 flex items-center justify-center rounded-full bg-[#25D366] text-white shadow-lg hover:bg-[#20bd5a] transition-colors pulse"
            aria-label="WhatsApp ile İletişime Geçin"
          >
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Layout; 