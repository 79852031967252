export const knowledgeBaseContent = {
  guides: [
    {
      id: 1,
      slug: "otomatik-kepenk-kullanim-rehberi",
      title: "Otomatik Kepenk Kullanım Rehberi",
      excerpt: "Otomatik kepenk sistemlerinizi doğru ve güvenli bir şekilde kullanmanız için kapsamlı rehber.",
      content: `
        <h1>Otomatik Kepenk Kullanım Rehberi</h1>
        
        <p>Otomatik kepenk sistemleri, işyerlerinizin güvenliğini sağlarken aynı zamanda kullanım kolaylığı sunar. Bu rehber, kepenk sisteminizi en verimli şekilde kullanmanıza yardımcı olacaktır.</p>

        <h2 class="text-primary">Günlük Kullanım</h2>
        <ul>
          <li>Kepenklerinizi açıp kapatırken kumandayı doğrudan kepenge yönlendirin</li>
          <li>Açma/kapama işlemi sırasında kepengin önünde engel olmadığından emin olun</li>
          <li>Kepenk tamamen açılana veya kapanana kadar bekleyin</li>
          <li>Acil durumlarda manuel kullanım için kılavuzu takip edin</li>
        </ul>

        <h2 class="text-primary">Güvenlik Önlemleri</h2>
        <ul>
          <li>Kepenk çalışırken altında durulmamalıdır</li>
          <li>Çocukların kumandayla oynamasına izin vermeyin</li>
          <li>Kepenk raylarının önünde engel bulundurmayın</li>
          <li>Anormal ses veya hareket durumunda servisi arayın</li>
        </ul>

        <h2 class="text-primary">Manuel Kullanım</h2>
        <p>Elektrik kesintisi durumunda manuel kullanım için:</p>
        <ol>
          <li>Manuel açma kolunu takın</li>
          <li>Kolu saat yönünde veya tersinde çevirin</li>
          <li>İşlem bitince kolu çıkarın</li>
          <li>Elektrik geldiğinde otomatik moda geçin</li>
        </ol>
      `,
      image: "/assets/service/service-1.webp",
      category: "Kullanım Kılavuzları",
      date: "2024-03-20",
      author: "Olgun Kepenk Teknik Ekibi"
    },
    {
      id: 2,
      slug: "kepenk-bakim-rehberi",
      title: "Kepenk Bakım Rehberi",
      excerpt: "Otomatik kepenk sistemlerinizin ömrünü uzatmak için periyodik bakım tavsiyeleri.",
      content: `
        <h1>Kepenk Bakım Rehberi</h1>
        
        <p>Düzenli bakım, kepenk sistemlerinizin uzun ömürlü ve sorunsuz çalışmasını sağlar. Bu rehber, temel bakım işlemlerini ve dikkat edilmesi gereken noktaları açıklar.</p>

        <h2 class="text-primary">Günlük Bakım</h2>
        <ul>
          <li>Ray içlerinin temiz tutulması</li>
          <li>Kepenk yüzeyinin temizlenmesi</li>
          <li>Anormal ses kontrolü</li>
          <li>Hareket kontrolü</li>
        </ul>

        <h2 class="text-primary">Aylık Bakım</h2>
        <ul>
          <li>Ray yağlama işlemi</li>
          <li>Civata ve bağlantı kontrolü</li>
          <li>Motor ses kontrolü</li>
          <li>Kumanda pil kontrolü</li>
        </ul>

        <h2 class="text-primary">Yıllık Bakım</h2>
        <p>Profesyonel servis tarafından yapılması gereken kontroller:</p>
        <ul>
          <li>Motor bakımı</li>
          <li>Elektronik aksam kontrolü</li>
          <li>Yay sistemi kontrolü</li>
          <li>Genel güvenlik kontrolü</li>
        </ul>
      `,
      image: "/assets/service/service-2.webp",
      category: "Bakım Tavsiyeleri",
      date: "2024-03-21",
      author: "Olgun Kepenk Teknik Ekibi"
    },
    {
      id: 3,
      slug: "kepenk-ariza-teshis-rehberi",
      title: "Kepenk Arıza Teşhis Rehberi",
      excerpt: "Sık karşılaşılan kepenk sorunları ve çözüm yolları hakkında teknik bilgiler.",
      content: `
        <h1>Kepenk Arıza Teşhis Rehberi</h1>
        
        <p>Bu rehber, sık karşılaşılan kepenk sorunlarını tanımlamanıza ve basit çözümleri uygulamanıza yardımcı olacaktır.</p>

        <h2 class="text-primary">Motor Sorunları</h2>
        <ul>
          <li>Motor çalışmıyor
            <ul>
              <li>Elektrik bağlantısını kontrol edin</li>
              <li>Sigortaları kontrol edin</li>
              <li>Kumanda pilini kontrol edin</li>
            </ul>
          </li>
          <li>Motor sesi var ama kepenk hareket etmiyor
            <ul>
              <li>Manuel açma kolunu kontrol edin</li>
              <li>Motor bağlantılarını kontrol edin</li>
            </ul>
          </li>
        </ul>

        <h2 class="text-primary">Kumanda Sorunları</h2>
        <ul>
          <li>Kumanda çalışmıyor
            <ul>
              <li>Pil değişimi yapın</li>
              <li>Kumanda kodlamasını kontrol edin</li>
            </ul>
          </li>
          <li>Kumanda mesafesi kısa
            <ul>
              <li>Pil gücünü kontrol edin</li>
              <li>Sinyal engellerini kontrol edin</li>
            </ul>
          </li>
        </ul>

        <h2 class="text-primary">Mekanik Sorunlar</h2>
        <ul>
          <li>Kepenk takılıyor
            <ul>
              <li>Ray temizliği yapın</li>
              <li>Ray hasarını kontrol edin</li>
            </ul>
          </li>
          <li>Anormal ses
            <ul>
              <li>Yağlama yapın</li>
              <li>Bağlantıları kontrol edin</li>
            </ul>
          </li>
        </ul>
      `,
      image: "/assets/service/service-3.webp",
      category: "Teknik Dökümanlar",
      date: "2024-03-22",
      author: "Olgun Kepenk Teknik Ekibi"
    },
    {
      id: 4,
      slug: "kepenk-guvenlik-sistemleri-rehberi",
      title: "Kepenk Güvenlik Sistemleri Rehberi",
      excerpt: "Modern kepenk güvenlik sistemleri, özellikleri ve seçim kriterleri hakkında detaylı bilgiler.",
      content: `
        <h1>Kepenk Güvenlik Sistemleri Rehberi</h1>
        
        <p>Modern işyerleri için güvenlik sistemleri, kepenk sistemlerinin vazgeçilmez bir parçasıdır. Bu rehber, mevcut güvenlik seçeneklerini ve en uygun sistemi seçmenize yardımcı olacak bilgileri içerir.</p>

        <h2 class="text-primary">Temel Güvenlik Özellikleri</h2>
        <ul>
          <li>Otomatik Kilitleme Sistemi
            <ul>
              <li>Elektrikli kilit mekanizması</li>
              <li>Çift yönlü kilitleme</li>
              <li>Uzaktan kontrol</li>
            </ul>
          </li>
          <li>Alarm Sistemleri
            <ul>
              <li>Hareket sensörleri</li>
              <li>Titreşim algılama</li>
              <li>Sesli uyarı sistemi</li>
            </ul>
          </li>
        </ul>

        <h2 class="text-primary">İleri Güvenlik Özellikleri</h2>
        <ul>
          <li>Akıllı Telefon Entegrasyonu
            <ul>
              <li>Mobil uygulama kontrolü</li>
              <li>Anlık bildirimler</li>
              <li>Uzaktan izleme</li>
            </ul>
          </li>
          <li>Kamera Sistemleri
            <ul>
              <li>Gece görüş özelliği</li>
              <li>Hareket kayıt</li>
              <li>Bulut depolama</li>
            </ul>
          </li>
        </ul>

        <h2 class="text-primary">Güvenlik Sistemi Seçimi</h2>
        <p>İşletmeniz için en uygun güvenlik sistemini seçerken dikkat edilmesi gerekenler:</p>
        <ul>
          <li>İşletme büyüklüğü ve türü</li>
          <li>Risk faktörleri</li>
          <li>Bütçe planlaması</li>
          <li>Kullanım kolaylığı</li>
        </ul>
      `,
      image: "/assets/service/service-4.webp",
      category: "Teknik Dökümanlar",
      date: "2024-03-23",
      author: "Olgun Kepenk Teknik Ekibi"
    },
    {
      id: 5,
      slug: "kepenk-motor-secim-rehberi",
      title: "Kepenk Motor Seçim Rehberi",
      excerpt: "Kepenk motorları, güç hesaplamaları ve doğru motor seçimi için kapsamlı rehber.",
      content: `
        <h1>Kepenk Motor Seçim Rehberi</h1>
        
        <p>Doğru motor seçimi, kepenk sisteminin performansı ve ömrü için kritik öneme sahiptir. Bu rehber, ihtiyacınıza uygun motoru seçmenize yardımcı olacaktır.</p>

        <h2 class="text-primary">Motor Tipleri</h2>
        <ul>
          <li>Santral Motorlar
            <ul>
              <li>Yüksek güç kapasitesi</li>
              <li>Ağır yük kaldırma</li>
              <li>Endüstriyel kullanım</li>
            </ul>
          </li>
          <li>Tüp Motorlar
            <ul>
              <li>Kompakt tasarım</li>
              <li>Kolay montaj</li>
              <li>Sessiz çalışma</li>
            </ul>
          </li>
        </ul>

        <h2 class="text-primary">Güç Hesaplama</h2>
        <p>Motor gücü hesaplaması için gerekli faktörler:</p>
        <ul>
          <li>Kepenk ağırlığı</li>
          <li>Kepenk yüksekliği</li>
          <li>Kullanım sıklığı</li>
          <li>Çalışma ortamı</li>
        </ul>

        <h2 class="text-primary">Motor Özellikleri</h2>
        <ul>
          <li>Güç Seçenekleri
            <ul>
              <li>300Nm - 600Nm arası</li>
              <li>Monofaze/Trifaze</li>
            </ul>
          </li>
          <li>Ek Özellikler
            <ul>
              <li>Manuel açma sistemi</li>
              <li>Termal koruma</li>
              <li>IP koruma sınıfı</li>
            </ul>
          </li>
        </ul>
      `,
      image: "/assets/service/service-1.webp",
      category: "Teknik Dökümanlar",
      date: "2024-03-24",
      author: "Olgun Kepenk Teknik Ekibi"
    },
    {
      id: 6,
      slug: "kepenk-yedek-parca-rehberi",
      title: "Kepenk Yedek Parça Rehberi",
      excerpt: "Kepenk sistemlerinde kullanılan yedek parçalar ve değişim süreçleri hakkında bilgiler.",
      content: `
        <h1>Kepenk Yedek Parça Rehberi</h1>
        
        <p>Kepenk sistemlerinin sorunsuz çalışması için kaliteli yedek parça kullanımı ve düzenli parça değişimi önemlidir. Bu rehber, temel yedek parçalar ve değişim süreçleri hakkında bilgi verir.</p>

        <h2 class="text-primary">Temel Yedek Parçalar</h2>
        <ul>
          <li>Motor Parçaları
            <ul>
              <li>Dişli grubu</li>
              <li>Rulmanlar</li>
              <li>Kaplin</li>
            </ul>
          </li>
          <li>Mekanik Parçalar
            <ul>
              <li>Raylar</li>
              <li>Makaralar</li>
              <li>Yaylar</li>
            </ul>
          </li>
        </ul>

        <h2 class="text-primary">Elektronik Parçalar</h2>
        <ul>
          <li>Kumanda Sistemleri
            <ul>
              <li>Alıcı kartlar</li>
              <li>Kumandalar</li>
              <li>Sensörler</li>
            </ul>
          </li>
          <li>Güvenlik Ekipmanları
            <ul>
              <li>Fotoseller</li>
              <li>Flaşörler</li>
              <li>Butonlar</li>
            </ul>
          </li>
        </ul>

        <h2 class="text-primary">Parça Değişim Süreçleri</h2>
        <p>Yedek parça değişiminde dikkat edilmesi gerekenler:</p>
        <ul>
          <li>Orijinal parça kullanımı</li>
          <li>Profesyonel montaj</li>
          <li>Garanti şartları</li>
          <li>Periyodik kontrol</li>
        </ul>
      `,
      image: "/assets/service/service-2.webp",
      category: "Bakım Tavsiyeleri",
      date: "2024-03-25",
      author: "Olgun Kepenk Teknik Ekibi"
    },
    {
      id: 7,
      slug: "kepenk-montaj-rehberi",
      title: "Kepenk Montaj Rehberi",
      excerpt: "Otomatik kepenk sistemlerinin montaj aşamaları ve dikkat edilmesi gereken noktalar.",
      content: `
        <h1>Kepenk Montaj Rehberi</h1>
        
        <p>Kepenk sistemlerinin güvenli ve sorunsuz çalışması için doğru montaj kritik öneme sahiptir. Bu rehber, montaj sürecindeki önemli aşamaları ve kontrol noktalarını açıklar.</p>

        <h2 class="text-primary">Montaj Öncesi Hazırlık</h2>
        <ul>
          <li>Ölçü Alma
            <ul>
              <li>Genişlik ölçüsü</li>
              <li>Yükseklik ölçüsü</li>
              <li>Montaj alanı kontrolü</li>
            </ul>
          </li>
          <li>Malzeme Kontrolü
            <ul>
              <li>Parça listesi kontrolü</li>
              <li>Ekipman hazırlığı</li>
              <li>Güvenlik ekipmanları</li>
            </ul>
          </li>
        </ul>

        <h2 class="text-primary">Montaj Aşamaları</h2>
        <ul>
          <li>Ray Montajı
            <ul>
              <li>Ray hizalama</li>
              <li>Sabitleme işlemi</li>
              <li>Teraziye alma</li>
            </ul>
          </li>
          <li>Motor Montajı
            <ul>
              <li>Motor yerleşimi</li>
              <li>Elektrik bağlantısı</li>
              <li>Test işlemi</li>
            </ul>
          </li>
        </ul>

        <h2 class="text-primary">Son Kontroller</h2>
        <p>Montaj sonrası kontrol edilmesi gerekenler:</p>
        <ul>
          <li>Hareket kontrolü</li>
          <li>Güvenlik testleri</li>
          <li>Kullanıcı eğitimi</li>
          <li>Garanti belgesi</li>
        </ul>
      `,
      image: "/assets/service/service-3.webp",
      category: "Kullanım Kılavuzları",
      date: "2024-03-26",
      author: "Olgun Kepenk Teknik Ekibi"
    },
    {
      id: 8,
      slug: "kepenk-otomasyon-sistemleri-rehberi",
      title: "Kepenk Otomasyon Sistemleri Rehberi",
      excerpt: "Modern kepenk otomasyon sistemleri, akıllı kontrol seçenekleri ve kurulum bilgileri.",
      content: `
        <h1>Kepenk Otomasyon Sistemleri Rehberi</h1>
        
        <p>Modern kepenk sistemleri, gelişmiş otomasyon özellikleriyle işletmelere büyük kolaylık sağlar. Bu rehber, otomasyon seçenekleri ve kurulum süreçleri hakkında detaylı bilgi verir.</p>

        <h2 class="text-primary">Otomasyon Tipleri</h2>
        <ul>
          <li>Uzaktan Kontrol Sistemleri
            <ul>
              <li>RF kumandalar</li>
              <li>Bluetooth kontrol</li>
              <li>Wi-Fi bağlantı</li>
            </ul>
          </li>
          <li>Akıllı Sistemler
            <ul>
              <li>Mobil uygulama kontrolü</li>
              <li>Sesli komut</li>
              <li>Otomatik programlama</li>
            </ul>
          </li>
        </ul>

        <h2 class="text-primary">Güvenlik Özellikleri</h2>
        <ul>
          <li>Sensör Sistemleri
            <ul>
              <li>Hareket algılama</li>
              <li>Engel algılama</li>
              <li>Sıkışma koruması</li>
            </ul>
          </li>
          <li>İzleme Sistemleri
            <ul>
              <li>Durum bildirimleri</li>
              <li>Hata raporlama</li>
              <li>Uzaktan izleme</li>
            </ul>
          </li>
        </ul>

        <h2 class="text-primary">Kurulum ve Entegrasyon</h2>
        <p>Otomasyon sistemi kurulumunda dikkat edilecek noktalar:</p>
        <ul>
          <li>Sistem uyumluluğu</li>
          <li>Bağlantı gereksinimleri</li>
          <li>Kullanıcı eğitimi</li>
          <li>Bakım planlaması</li>
        </ul>
      `,
      image: "/assets/service/service-4.webp",
      category: "Teknik Dökümanlar",
      date: "2024-03-27",
      author: "Olgun Kepenk Teknik Ekibi"
    }
  ],
  categories: [
    {
      id: "kullanim-kilavuzlari",
      title: "Kullanım Kılavuzları",
      description: "Otomatik kepenk sistemlerinin doğru ve güvenli kullanımı için detaylı kılavuzlar."
    },
    {
      id: "bakim-tavsiyeleri",
      title: "Bakım Tavsiyeleri",
      description: "Kepenk sistemlerinizin ömrünü uzatmak için bakım önerileri ve kontrol listeleri."
    },
    {
      id: "teknik-dokumanlar",
      title: "Teknik Dökümanlar",
      description: "Kepenk sistemleri hakkında teknik bilgiler, arıza teşhis ve çözüm yöntemleri."
    }
  ]
}; 