import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { knowledgeBaseContent } from '../config/knowledgeBase';
import { FaBook, FaTools, FaFileAlt, FaSearch } from 'react-icons/fa';
import ImageWithFallback from '../components/ImageWithFallback';

const KnowledgeBase = () => {
  const { guides, categories } = knowledgeBaseContent;
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  // Arama fonksiyonu
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    
    if (query.length > 2) {
      setIsSearching(true);
      const results = guides.filter(guide => 
        guide.title.toLowerCase().includes(query) ||
        guide.excerpt.toLowerCase().includes(query) ||
        guide.content.toLowerCase().includes(query)
      );
      setSearchResults(results);
    } else {
      setIsSearching(false);
      setSearchResults([]);
    }
  };

  return (
    <>
      <Helmet>
        <title>Kepenk Bilgi Merkezi | Olgun Kepenk</title>
        <meta
          name="description"
          content="Otomatik kepenk sistemleri hakkında detaylı bilgiler, kullanım kılavuzları, bakım tavsiyeleri ve teknik dökümanlar."
        />
        <meta
          name="keywords"
          content="kepenk bilgi merkezi, kepenk kullanım kılavuzu, kepenk bakım rehberi, kepenk teknik dökümanlar"
        />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        {/* Hero Section */}
        <div className="bg-gradient-to-r from-primary to-primary/80 rounded-xl p-8 mb-12 text-white">
          <h1 className="text-4xl font-bold mb-4">Kepenk Bilgi Merkezi</h1>
          <p className="text-lg mb-8">
            Otomatik kepenk sistemleri hakkında ihtiyacınız olan tüm bilgiler, rehberler ve teknik dökümanlar.
          </p>
          <div className="relative max-w-xl">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Rehberlerde ara... (en az 3 karakter)"
              className="w-full px-4 py-3 pl-12 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-white"
            />
            <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
          
          {/* Arama Sonuçları */}
          {isSearching && (
            <div className="mt-4 bg-white rounded-lg shadow-lg p-4 text-gray-800 max-h-96 overflow-y-auto">
              <h3 className="font-bold mb-4">
                {searchResults.length > 0 
                  ? `${searchResults.length} sonuç bulundu`
                  : 'Sonuç bulunamadı'}
              </h3>
              {searchResults.map(result => (
                <Link
                  key={result.id}
                  to={`/bilgi-merkezi/rehber/${result.slug}`}
                  className="block p-4 hover:bg-gray-50 rounded-lg mb-2"
                >
                  <h4 className="font-bold text-primary">{result.title}</h4>
                  <p className="text-sm text-gray-600">{result.excerpt}</p>
                  <span className="text-xs text-gray-500 mt-2 block">{result.category}</span>
                </Link>
              ))}
            </div>
          )}
        </div>

        {/* Categories */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          {categories.map((category) => (
            <div
              key={category.id}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="text-primary mb-4">
                {category.id === 'kullanim-kilavuzlari' && <FaBook size={24} />}
                {category.id === 'bakim-tavsiyeleri' && <FaTools size={24} />}
                {category.id === 'teknik-dokumanlar' && <FaFileAlt size={24} />}
              </div>
              <h2 className="text-xl font-bold mb-2">{category.title}</h2>
              <p className="text-gray-600 mb-4">{category.description}</p>
              <Link
                to={`/bilgi-merkezi/kategori/${category.id}`}
                className="text-primary hover:text-primary/80"
              >
                Rehberleri Görüntüle →
              </Link>
            </div>
          ))}
        </div>

        {/* Latest Guides */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-8">Son Eklenen Rehberler</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {guides.slice(-6).reverse().map((guide) => (
              <div
                key={guide.id}
                className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
              >
                <ImageWithFallback
                  src={guide.image}
                  alt={guide.title}
                  className="w-full h-48 object-cover"
                  width={400}
                  height={300}
                />
                <div className="p-6">
                  <span className="inline-block px-3 py-1 bg-primary/10 text-primary rounded-full text-sm mb-4">
                    {guide.category}
                  </span>
                  <h3 className="text-xl font-bold mb-2">{guide.title}</h3>
                  <p className="text-gray-600 mb-4">{guide.excerpt}</p>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">{guide.date}</span>
                    <Link
                      to={`/bilgi-merkezi/rehber/${guide.slug}`}
                      className="text-primary hover:text-primary/80"
                    >
                      Devamını Oku →
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Call to Action */}
        <div className="bg-gray-50 rounded-xl p-8 text-center">
          <h2 className="text-2xl font-bold mb-4">Aradığınızı Bulamadınız mı?</h2>
          <p className="text-gray-600 mb-6">
            Teknik ekibimiz sorularınızı yanıtlamak için hazır.
          </p>
          <Link
            to="/iletisim"
            className="inline-block bg-primary text-white px-8 py-3 rounded-lg hover:bg-primary/90 transition-colors"
          >
            Bize Ulaşın
          </Link>
        </div>
      </div>
    </>
  );
};

export default KnowledgeBase; 