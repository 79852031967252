export const priceConfig = {
    sacKepenk: {
        3: 6250,  
        4: 7000,  
        5: 8600,  
        6: 9500,  
        7: 10250, 
        8: 11250, 
        9: 12100, 
        10: 12750,
        11: 13750,
        12: 14500,
        13: 15500,
        14: 16250,
        15: 17000,
        16: 18000,
        17: 18750,
        18: 19900,
        19: 20000,
        20: 21250 
    },
    multipliers: {
        sacGorunumluKepenk: 1.5,
        aluminyumKepenk: 2
    },
    additionalCosts: {
        underTwelveSquareMeters: 5000,
        overTwelveSquareMeters: 7500
    }
}; 