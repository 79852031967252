import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import ServiceDetail from './pages/ServiceDetail';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import DistrictDetail from './pages/DistrictDetail';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import KnowledgeBase from './pages/KnowledgeBase';
import GuideDetail from './pages/GuideDetail';
import GuideCategory from './pages/GuideCategory';

function ScrollToTop() {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
      <SpeedInsights />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/hakkimizda" element={<About />} />
          <Route path="/iletisim" element={<Contact />} />
          <Route path="/kepenk-servisi" element={<ServiceDetail service="kepenk-servisi" />} />
          <Route path="/kepenk-tamiri" element={<ServiceDetail service="kepenk-tamiri" />} />
          <Route path="/sifir-kepenk" element={<ServiceDetail service="sifir-kepenk" />} />
          <Route path="/kepenk-parcalari" element={<ServiceDetail service="kepenk-parcalari" />} />
          <Route path="/:district" element={<DistrictDetail />} />
          
          {/* Bilgi Merkezi Rotaları */}
          <Route path="/bilgi-merkezi" element={<KnowledgeBase />} />
          <Route path="/bilgi-merkezi/rehber/:slug" element={<GuideDetail />} />
          <Route path="/bilgi-merkezi/kategori/:categoryId" element={<GuideCategory />} />
        </Routes>
      </Layout>
      <Analytics />
    </Router>
  );
}

export default App;
