import React from 'react';
import { Link } from 'react-router-dom';
import { contactConfig } from '../config/contactConfig';

const Footer = () => {
  return (
    <footer className="bg-primary text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <h3 className="text-xl font-bold mb-4">Olgun Kepenk</h3>
            <p className="mb-4">
              Kaliteli ve güvenilir kepenk sistemleri için doğru adres.
            </p>
            <div className="space-y-2">
              <p>
                <a href={`tel:${contactConfig.phone}`} className="hover:text-secondary">
                  📞 {contactConfig.phoneDisplay}
                </a>
              </p>
              <p>
                <a href={`mailto:${contactConfig.email}`} className="hover:text-secondary">
                  📧 {contactConfig.email}
                </a>
              </p>
              <p>📍 {contactConfig.address}</p>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-xl font-bold mb-4">Hızlı Bağlantılar</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="hover:text-secondary">
                  Anasayfa
                </Link>
              </li>
              <li>
                <Link to="/hakkimizda" className="hover:text-secondary">
                  Hakkımızda
                </Link>
              </li>
              <li>
                <Link to="/blog" className="hover:text-secondary">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/bilgi-merkezi" className="hover:text-secondary">
                  Bilgi Merkezi
                </Link>
              </li>
              <li>
                <Link to="/iletisim" className="hover:text-secondary">
                  İletişim
                </Link>
              </li>
            </ul>
          </div>

          {/* Services */}
          <div>
            <h3 className="text-xl font-bold mb-4">Hizmetlerimiz</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/kepenk-servisi" className="hover:text-secondary">
                  Otomatik Kepenk Servisi
                </Link>
              </li>
              <li>
                <Link to="/kepenk-tamiri" className="hover:text-secondary">
                  Otomatik Kepenk Tamiri
                </Link>
              </li>
              <li>
                <Link to="/sifir-kepenk" className="hover:text-secondary">
                  Sıfır Kepenk
                </Link>
              </li>
              <li>
                <Link to="/kepenk-parcalari" className="hover:text-secondary">
                  Kepenk Parçaları
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources */}
          <div>
            <h3 className="text-xl font-bold mb-4">Kaynaklar</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/bilgi-merkezi/kategori/kullanim-kilavuzlari" className="hover:text-secondary">
                  Kullanım Kılavuzları
                </Link>
              </li>
              <li>
                <Link to="/bilgi-merkezi/kategori/bakim-tavsiyeleri" className="hover:text-secondary">
                  Bakım Tavsiyeleri
                </Link>
              </li>
              <li>
                <Link to="/bilgi-merkezi/kategori/teknik-dokumanlar" className="hover:text-secondary">
                  Teknik Dökümanlar
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-primary-light mt-8 pt-8 text-center">
          <p>© {new Date().getFullYear()} Olgun Kepenk. Tüm hakları saklıdır.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 