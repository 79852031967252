import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { contactConfig } from '../config/contactConfig';

const services = [
  { 
    name: 'Otomatik Kepenk Servisi', 
    href: '/kepenk-servisi', 
    description: '7/24 Acil Kepenk Servisi',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
      </svg>
    )
  },
  { 
    name: 'Otomatik Kepenk Tamiri', 
    href: '/kepenk-tamiri', 
    description: 'Garantili Kepenk Tamiri',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
      </svg>
    )
  },
  { 
    name: 'Sıfır Kepenk', 
    href: '/sifir-kepenk', 
    description: 'Modern ve Güvenilir Sistemler',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
      </svg>
    )
  },
  { 
    name: 'Kepenk Parçaları', 
    href: '/kepenk-parcalari', 
    description: 'Kaliteli Yedek Parçalar',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
      </svg>
    )
  },
];

const navigation = [
  { name: 'Hakkımızda', href: '/hakkimizda' },
  { name: 'Hizmetlerimiz', type: 'dropdown' },
  { name: 'Blog', href: '/blog' },
  { name: 'İletişim', href: '/iletisim' },
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <header className="fixed top-0 left-0 right-0 bg-primary shadow-md z-50">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center h-20">
            <Link to="/" className="text-2xl md:text-3xl font-bold text-white">
              Olgun Kepenk
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-6">
              {navigation.map((item) => 
                item.type === 'dropdown' ? (
                  <div key={item.name} className="relative group">
                    <button className="text-white px-4 py-3 rounded-md text-lg font-medium transition-all duration-200 flex items-center group hover:bg-white hover:text-primary">
                      <span>Hizmetlerimiz</span>
                      <ChevronDownIcon className="w-5 h-5 ml-1 transform group-hover:rotate-180 transition-transform duration-200" />
                    </button>
                    {/* Dropdown Menu */}
                    <div className="absolute left-0 w-80 pt-3 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 ease-out">
                      <div className="relative bg-white rounded-lg shadow-xl ring-1 ring-black/5 overflow-hidden">
                        {/* Dropdown Arrow */}
                        <div className="absolute top-0 left-6 -translate-y-2 w-3 h-3 bg-white transform rotate-45 border-t border-l border-black/5" />
                        
                        {/* Menu Items */}
                        <div className="relative bg-white rounded-lg divide-y divide-gray-100">
                          {services.map((service, idx) => (
                            <Link
                              key={service.name}
                              to={service.href}
                              className="block transition-all duration-200 hover:bg-gradient-to-r hover:from-primary/5 hover:to-transparent group/item"
                            >
                              <div className="p-4 flex items-start space-x-4">
                                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center text-primary group-hover/item:bg-primary group-hover/item:text-white transition-colors duration-200">
                                  {service.icon}
                                </div>
                                <div className="flex-1">
                                  <p className="text-base font-medium text-gray-900 group-hover/item:text-primary">
                                    {service.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500 group-hover/item:text-primary/70">
                                    {service.description}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="text-white hover:bg-white hover:text-primary px-4 py-3 rounded-md text-lg font-medium transition-colors duration-200"
                  >
                    {item.name}
                  </Link>
                )
              )}

              {/* Call Now Button */}
              <a
                href={`tel:${contactConfig.phone}`}
                className="relative inline-flex items-center gap-3 bg-white text-primary hover:text-white px-6 py-2.5 rounded-full text-base font-bold transition-all duration-300 hover:scale-105 group animate-[float_1.5s_ease-in-out_infinite]"
              >
                {/* Animated background */}
                <div className="absolute inset-0 rounded-full bg-gradient-to-r from-primary via-primary-light to-primary opacity-0 group-hover:opacity-100 transition-all duration-300" />
                
                {/* Button content */}
                <span className="relative flex items-center gap-2 z-10">
                  {/* Phone icon */}
                  <span className="relative">
                    <svg className="w-5 h-5 animate-[shake_0.5s_ease-in-out_infinite]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    {/* Ring effect */}
                    <span className="absolute -inset-2 border-2 border-primary/30 rounded-full animate-[ping_1.5s_ease-out_infinite]" />
                    <span className="absolute -inset-2.5 border-2 border-primary/20 rounded-full animate-[ping_1.5s_ease-out_infinite] delay-300" />
                  </span>
                  <span>Hemen Ara!</span>
                </span>

                {/* Keyframes */}
                <style jsx>{`
                  @keyframes shake {
                    0%, 100% { transform: rotate(-10deg); }
                    50% { transform: rotate(10deg); }
                  }
                  @keyframes float {
                    0%, 100% { transform: translateY(0); }
                    50% { transform: translateY(-8px); }
                  }
                `}</style>
              </a>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-primary-light focus:outline-none"
              >
                {isOpen ? (
                  <XMarkIcon className="block h-6 w-6" />
                ) : (
                  <Bars3Icon className="block h-6 w-6" />
                )}
              </button>
            </div>
          </div>

          {/* Mobile Navigation with Call Button */}
          {isOpen && (
            <div className="md:hidden pb-4">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => 
                  item.type === 'dropdown' ? (
                    <div key={item.name} className="space-y-1">
                      <div className="text-white px-3 py-2 text-lg font-medium border-b border-primary-light mb-2">
                        Hizmetlerimiz
                      </div>
                      <div className="bg-white/10 rounded-lg p-2 space-y-2">
                        {services.map((service) => (
                          <Link
                            key={service.name}
                            to={service.href}
                            className="block p-3 text-white hover:bg-white/20 rounded-md transition-colors duration-200"
                            onClick={() => setIsOpen(false)}
                          >
                            <div className="flex items-center space-x-3">
                              <div className="flex-shrink-0 w-8 h-8 rounded-full bg-white/10 flex items-center justify-center">
                                {service.icon}
                              </div>
                              <div>
                                <span className="block font-medium">{service.name}</span>
                                <span className="block text-sm text-white/80">{service.description}</span>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="text-white hover:bg-white/20 block px-3 py-2 rounded-md text-lg font-medium transition-colors duration-200"
                      onClick={() => setIsOpen(false)}
                    >
                      {item.name}
                    </Link>
                  )
                )}
                {/* Mobile Call Button */}
                <a
                  href={`tel:${contactConfig.phone}`}
                  className="block mt-4 text-center bg-white text-primary hover:bg-primary-light hover:text-white px-4 py-3 rounded-full text-lg font-semibold transition-all duration-300"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="flex items-center justify-center gap-2">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    Hemen Ara
                  </span>
                </a>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Header; 