import React, { useEffect, useState } from 'react';
import { FiPhoneCall } from 'react-icons/fi';
import { BsCalculator, BsWhatsapp } from 'react-icons/bs';
import { Helmet } from 'react-helmet';
import { products } from '../config/productsConfig';
import { priceConfig } from '../config/priceConfig';
import { contactConfig } from '../config/contactConfig';

const calculatePrice = (width, height) => {
  if (!width || !height) return null;

  // Santimetreden metrekareye çevirme
  const exactArea = (width * height) / 10000;
  const roundedArea = Math.ceil(exactArea);
  
  // Fiyat tablosundan en yakın üst değeri bulma
  const availableAreas = Object.keys(priceConfig.sacKepenk).map(Number);
  const baseArea = availableAreas.find(area => area >= roundedArea) || Math.max(...availableAreas);
  
  // Temel fiyat (Saç Kepenk)
  const sacKepenkPrice = priceConfig.sacKepenk[baseArea];
  
  // Diğer türler için fiyat hesaplama
  const sacGorunumluPrice = sacKepenkPrice * priceConfig.multipliers.sacGorunumluKepenk;
  const aluminyumPrice = sacKepenkPrice * priceConfig.multipliers.aluminyumKepenk;
  
  // Montaj ücreti
  const installationCost = roundedArea < 12 
    ? priceConfig.additionalCosts.underTwelveSquareMeters 
    : priceConfig.additionalCosts.overTwelveSquareMeters;

  return {
    area: exactArea.toFixed(2),
    roundedArea,
    prices: {
      sacKepenk: {
        basePrice: Math.round(sacKepenkPrice),
        totalPrice: Math.round(sacKepenkPrice + installationCost)
      },
      sacGorunumlu: {
        basePrice: Math.round(sacGorunumluPrice),
        totalPrice: Math.round(sacGorunumluPrice + installationCost)
      },
      aluminyum: {
        basePrice: Math.round(aluminyumPrice),
        totalPrice: Math.round(aluminyumPrice + installationCost)
      }
    },
    installationCost
  };
};

const PriceCalculator = () => {
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [result, setResult] = useState(null);

  const handleCalculate = () => {
    const price = calculatePrice(Number(width), Number(height));
    setResult(price);
  };

  const formatWhatsAppMessage = () => {
    if (!result) return '';
    
    return encodeURIComponent(
      `Merhaba, web sitenizden fiyat hesaplaması yaptım:\n\n` +
      `Ölçüler: ${width}cm x ${height}cm\n` +
      `Toplam Alan: ${result.area}m²\n\n` +
      `Hesaplanan Fiyatlar:\n` +
      `Saç Kepenk: ${result.prices.sacKepenk.totalPrice.toLocaleString('tr-TR')} TL\n` +
      `Saç Görünümlü Alüminyum Kepenk: ${result.prices.sacGorunumlu.totalPrice.toLocaleString('tr-TR')} TL\n` +
      `Alüminyum Kepenk: ${result.prices.aluminyum.totalPrice.toLocaleString('tr-TR')} TL\n\n` +
      `Hesaplanan fiyat alabilir miyim?`
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mt-8">
      <h3 className="text-2xl font-bold text-primary mb-6 flex items-center gap-2">
        <BsCalculator className="text-2xl" />
        Fiyat Hesaplama
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <label className="block text-gray-700 mb-2">Genişlik (cm)</label>
          <input
            type="number"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary"
            placeholder="Örn: 350"
          />
        </div>
        <div>
          <label className="block text-gray-700 mb-2">Yükseklik (cm)</label>
          <input
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary"
            placeholder="Örn: 240"
          />
        </div>
      </div>

      <button
        onClick={handleCalculate}
        className="w-full bg-primary text-white py-3 rounded-lg hover:bg-primary-dark transition-colors mb-6 flex items-center justify-center gap-2"
      >
        <BsCalculator className="text-xl" />
        Fiyat Hesapla
      </button>

      {result && (
        <div className="border-t pt-6">
          <h4 className="text-xl font-semibold mb-4">Hesaplama Sonucu</h4>
          <div className="space-y-4 text-gray-600">
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="font-medium">Toplam Alan</p>
              <p>{result.area} m²</p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {/* Saç Kepenk */}
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-semibold text-primary mb-2">Saç Kepenk</p>
                <p className="font-bold text-xl">
                  {result.prices.sacKepenk.totalPrice.toLocaleString('tr-TR')} TL
                </p>
              </div>

              {/* Saç Görünümlü Alüminyum */}
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-semibold text-primary mb-2">Saç Görünümlü Alüminyum Kepenk</p>
                <p className="font-bold text-xl">
                  {result.prices.sacGorunumlu.totalPrice.toLocaleString('tr-TR')} TL
                </p>
              </div>

              {/* Alüminyum Kepenk */}
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-semibold text-primary mb-2">Alüminyum Kepenk</p>
                <p className="font-bold text-xl">
                  {result.prices.aluminyum.totalPrice.toLocaleString('tr-TR')} TL
                </p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-6">
              <a
                href={`tel:${contactConfig.phone}`}
                className="flex items-center justify-center gap-2 bg-[#00bcd4] text-white py-3 px-6 rounded-lg hover:bg-[#00acc1] transition-colors"
              >
                <FiPhoneCall className="text-xl" />
                Hemen Ara
              </a>
              <a
                href={`https://wa.me/${contactConfig.whatsapp}?text=${formatWhatsAppMessage()}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center gap-2 bg-[#25d366] text-white py-3 px-6 rounded-lg hover:bg-[#22c55e] transition-colors"
              >
                <BsWhatsapp className="text-xl" />
                WhatsApp
              </a>
            </div>

            <p className="text-sm text-gray-500 mt-4">
              * Bu fiyatlar tahmini hesaplamadır. Kesin fiyat için lütfen bizimle iletişime geçin.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const services = {
  'kepenk-servisi': {
    title: 'İstanbul Otomatik Kepenk Servisi | 7/24 Kepenk Servisi | Olgun Kepenk',
    description: 'İstanbul\'da 7/24 profesyonel otomatik kepenk servisi ile işyerinizin güvenliğini garanti altına alıyoruz. 20 yıllık deneyimli kepenk servisi ekibimizle aynı gün kepenk servis hizmeti.',
    image: '/assets/service/service-1.webp',
    gallery: [
      '/assets/service/kepenk-servisi-2.webp',
      '/assets/service/kepenk-servisi-3.webp'
    ],
    sections: [
      {
        title: 'İstanbul\'un En Güvenilir Kepenk Servisi',
        content: `İstanbul'da profesyonel kepenk servisi arıyorsanız doğru yerdesiniz. Olgun Kepenk olarak, 20 yılı aşkın deneyimimizle İstanbul'un tüm ilçelerinde kepenk servisi hizmeti sunuyoruz. Uzman kepenk servisi ekibimiz ve modern ekipmanlarımızla, otomatik kepenk sistemlerinizin sorunsuz çalışmasını garanti ediyoruz.

        7/24 kepenk servisi hizmetimiz ile acil durumlarınızda yanınızdayız. Kepenk servisi ihtiyaçlarınıza en hızlı şekilde yanıt veriyoruz. Periyodik kepenk servisi ve bakım hizmetlerimizle, olası arızaların önüne geçerek işyerinizin güvenliğini kesintisiz sağlıyoruz.

        Profesyonel kepenk servisi ekibimiz, tüm marka ve model otomatik kepenkler için uzman servis hizmeti sunmaktadır. İstanbul'un her noktasında kepenk servisi ihtiyaçlarınız için bir telefon kadar yakınız. Kepenk servisinde kalite ve güvenilirliğin adresi Olgun Kepenk.`
      },
      {
        title: 'Profesyonel Kepenk Servisi Hizmetlerimiz',
        content: `İstanbul genelinde sunduğumuz kapsamlı kepenk servisi hizmetleri:

        • 7/24 Acil Kepenk Servisi
        • Periyodik Kepenk Bakım Servisi
        • Otomatik Kepenk Motor Servisi
        • Kepenk Kumanda Programlama
        • Kepenk Yay Sistemi Servisi
        • Kepenk Ray Sistemi Bakımı
        • Elektronik Kart Tamiri ve Servisi

        İstanbul'un tüm ilçelerinde profesyonel kepenk servisi hizmeti veriyoruz. Deneyimli kepenk servisi ekibimiz, en son teknoloji ekipmanlarla hizmetinizdedir.`
      },
      {
        title: 'Neden Olgun Kepenk Servisi?',
        content: `İstanbul'da kepenk servisi denilince akla gelen ilk firma olmamızın nedenleri:

        • 20 Yıllık Kepenk Servisi Deneyimi
        • 7/24 Acil Kepenk Servisi Hizmeti
        • Aynı Gün Kepenk Servis Garantisi
        • Profesyonel Servis Ekibi
        • Orijinal Yedek Parça Garantisi
        • Uygun Kepenk Servisi Fiyatları
        • İstanbul'un Tüm İlçelerine Servis

        Kepenk servisinde kalite ve güvenilirlik için Olgun Kepenk'i tercih edin.`
      }
    ],
    metaDescription: 'İstanbul\'da profesyonel kepenk servisi. ✓7/24 Acil Kepenk Servisi ✓Aynı Gün Servis ✓20 Yıllık Deneyim ✓Garantili Hizmet. Tüm İstanbul\'da kepenk servisi için hemen arayın! ☎ 0552 858 00 60',
    keywords: 'kepenk servisi, otomatik kepenk servisi, 7/24 kepenk servisi, acil kepenk servisi, İstanbul kepenk servisi, profesyonel kepenk servisi, kepenk tamir servisi, kepenk bakım servisi, kepenk motor servisi',
    faqs: [
      {
        question: 'Kepenk servisi ne kadar sürede gelir?',
        answer: 'İstanbul içi kepenk servisi çağrılarına ortalama 30-45 dakika içinde yanıt veriyoruz. Acil kepenk servisi taleplerinize en hızlı şekilde müdahale ediyoruz.'
      },
      {
        question: 'Kepenk servisi ücretleri nasıl belirlenir?',
        answer: 'Kepenk servisi ücretleri, arızanın türüne, kullanılacak yedek parçalara ve işçilik süresine göre değişiklik gösterir. Servis öncesi detaylı fiyat bilgisi veriyoruz.'
      },
      {
        question: 'Hangi bölgelere kepenk servisi hizmeti veriyorsunuz?',
        answer: 'İstanbul\'un tüm ilçelerine kepenk servisi hizmeti veriyoruz. Avrupa ve Anadolu yakasında 7/24 kepenk servisi hizmetimiz mevcuttur.'
      },
      {
        question: 'Kepenk servisi garanti kapsamında mı?',
        answer: 'Evet, tüm kepenk servisi hizmetlerimiz ve kullanılan yedek parçalar 1 yıl garanti kapsamındadır.'
      },
      {
        question: 'Ne sıklıkla kepenk servisi yapılmalıdır?',
        answer: 'Otomatik kepenk sistemlerinin sorunsuz çalışması için 6 ayda bir düzenli kepenk servisi yapılmasını öneriyoruz. Yoğun kullanımda bu süre 3 aya inebilir.'
      },
      {
        question: 'Kepenk servisi için hangi markalara hizmet veriyorsunuz?',
        answer: 'Tüm marka ve model otomatik kepenkler için profesyonel kepenk servisi hizmeti veriyoruz. Nice, Somfy, BFT, Came gibi önde gelen markaların yetkili kepenk servisi olarak hizmet veriyoruz.'
      },
      {
        question: 'Acil kepenk servisi hizmetiniz var mı?',
        answer: 'Evet, 7/24 acil kepenk servisi hizmetimiz mevcuttur. İstanbul\'un tüm ilçelerinde, gece gündüz demeden acil kepenk servisi hizmeti veriyoruz.'
      },
      {
        question: 'Kepenk servisi için yedek parça stoğunuz var mı?',
        answer: 'Evet, tüm yaygın kepenk modelleri için orijinal yedek parça stoğumuz mevcuttur. Kepenk servisi esnasında gerekli parçaları hemen temin edebiliyoruz.'
      }
    ]
  },
  'kepenk-tamiri': {
    title: 'İstanbul Otomatik Kepenk Tamiri | Aynı Gün Kepenk Tamiri | Olgun Kepenk',
    description: 'İstanbul\'da profesyonel otomatik kepenk tamiri hizmeti. 20 yıllık deneyimli ekibimizle aynı gün kepenk tamir garantisi. Uygun fiyat, kaliteli hizmet.',
    image: '/assets/service/service-2.webp',
    gallery: [
      '/assets/service/kepenk-tamiri-2.webp',
      '/assets/service/kepenk-tamiri-3.webp'
    ],
    sections: [
      {
        title: 'İstanbul\'da Profesyonel Kepenk Tamiri',
        content: `İstanbul'un tüm ilçelerinde profesyonel kepenk tamiri hizmeti sunuyoruz. Olgun Kepenk olarak, 20 yıllık deneyimimizle otomatik kepenk tamiri konusunda uzmanlaşmış ekibimizle yanınızdayız. Kepenk tamiri ihtiyaçlarınıza aynı gün çözüm sunuyoruz.

        Kepenk tamiri konusunda uzman ekibimiz, tüm marka ve model otomatik kepenkler için hızlı ve kalıcı çözümler üretir. Motor arızası, elektronik kart sorunu, kumanda problemi veya mekanik aksamlardaki her türlü kepenk tamiri işlemini garantili olarak gerçekleştiriyoruz.

        7/24 acil kepenk tamiri hizmetimizle, işyerinizin güvenliğini kesintisiz sağlıyoruz. İstanbul'da kepenk tamiri için bizi tercih eden müşterilerimize kaliteli ve uygun fiyatlı hizmet sunuyoruz.`
      },
      {
        title: 'Kepenk Tamiri Hizmetlerimiz',
        content: `İstanbul genelinde sunduğumuz kapsamlı kepenk tamiri hizmetleri:

        • Otomatik Kepenk Motor Tamiri
        • Kepenk Elektronik Kart Tamiri
        • Kepenk Kumanda Tamiri
        • Kepenk Yay Sistemi Tamiri
        • Kepenk Ray Sistemi Tamiri
        • Kepenk Dişli Tamiri
        • Kepenk Şaft Tamiri

        Profesyonel kepenk tamiri ekibimiz, en son teknoloji ekipmanlarla hizmetinizdedir. Orijinal yedek parça garantisiyle kepenk tamiri yapıyoruz.`
      },
      {
        title: 'Neden Olgun Kepenk Tamiri?',
        content: `İstanbul'da kepenk tamiri denilince akla gelen ilk firma olmamızın nedenleri:

        • 20 Yıllık Kepenk Tamiri Deneyimi
        • Aynı Gün Kepenk Tamir Garantisi
        • 7/24 Acil Kepenk Tamiri Hizmeti
        • Uygun Kepenk Tamiri Fiyatları
        • Orijinal Yedek Parça Garantisi
        • Profesyonel Tamir Ekibi
        • İstanbul'un Tüm İlçelerine Hizmet

        Kepenk tamirinde kalite ve güvenilirlik için Olgun Kepenk'i tercih edin.`
      }
    ],
    metaDescription: 'İstanbul\'da profesyonel kepenk tamiri. ✓Aynı Gün Tamir ✓7/24 Acil Servis ✓20 Yıllık Deneyim ✓Garantili Hizmet. Tüm İstanbul\'da kepenk tamiri için hemen arayın! ☎ 0552 858 00 60',
    keywords: 'kepenk tamiri, otomatik kepenk tamiri, acil kepenk tamiri, İstanbul kepenk tamiri, kepenk tamir servisi, kepenk motor tamiri, kepenk yay tamiri, kepenk arıza tamiri',
    faqs: [
      {
        question: 'Kepenk tamiri ne kadar sürer?',
        answer: 'Kepenk tamiri süresi arızanın türüne göre değişmekle birlikte, genellikle 1-2 saat içinde tamamlanır. Acil kepenk tamiri hizmetimizle en kısa sürede yanınızdayız.'
      },
      {
        question: 'Kepenk tamiri ücretleri nasıl belirlenir?',
        answer: 'Kepenk tamiri ücretleri, arızanın türüne, değişmesi gereken parçalara ve işçilik süresine göre belirlenir. Kepenk tamiri öncesi detaylı fiyat bilgisi veriyoruz.'
      },
      {
        question: 'Kepenk tamiri garantili mi?',
        answer: 'Evet, tüm kepenk tamiri hizmetlerimiz ve kullanılan yedek parçalar 1 yıl garanti kapsamındadır. Orijinal yedek parça kullanımı ile kalıcı çözümler sunuyoruz.'
      },
      {
        question: 'Acil kepenk tamiri hizmetiniz var mı?',
        answer: 'Evet, 7/24 acil kepenk tamiri hizmetimiz mevcuttur. İstanbul\'un tüm ilçelerinde, gece gündüz demeden acil kepenk tamiri hizmeti veriyoruz.'
      },
      {
        question: 'Hangi marka kepenkler için tamir hizmeti veriyorsunuz?',
        answer: 'Tüm marka ve model otomatik kepenkler için profesyonel kepenk tamiri hizmeti veriyoruz. Nice, Somfy, BFT, Came gibi önde gelen markaların yetkili servisiyiz.'
      },
      {
        question: 'Kepenk tamiri için yerinde hizmet veriyor musunuz?',
        answer: 'Evet, tüm kepenk tamiri hizmetlerimizi yerinde veriyoruz. Mobil servis araçlarımızla İstanbul\'un her noktasına hızlıca ulaşıyoruz.'
      },
      {
        question: 'Kepenk tamiri için yedek parça stoğunuz var mı?',
        answer: 'Evet, yaygın kullanılan tüm kepenk modelleri için orijinal yedek parça stoğumuz mevcuttur. Kepenk tamiri esnasında gerekli parçaları hemen temin edebiliyoruz.'
      },
      {
        question: 'Kepenk tamiri sonrası bakım hizmeti veriyor musunuz?',
        answer: 'Evet, kepenk tamiri sonrası düzenli bakım hizmeti sunuyoruz. Periyodik bakımlarla kepenk sisteminizin ömrünü uzatıyor ve arıza riskini minimuma indiriyoruz.'
      }
    ]
  },
  'sifir-kepenk': {
    title: 'İstanbul Otomatik Kepenk İmalatı | Sıfır Kepenk Fiyatları | Olgun Kepenk',
    description: 'İstanbul\'da kaliteli ve uygun fiyatlı sıfır otomatik kepenk sistemleri. Modern tasarım, profesyonel montaj ve 2 yıl garanti. Ücretsiz keşif hizmeti.',
    image: '/assets/service/service-3.webp',
    gallery: [
      '/assets/service/sifir-kepenk-2.webp',
      '/assets/service/sifir-kepenk-3.webp'
    ],
    sections: [
      {
        title: 'Modern Sıfır Kepenk Sistemleri',
        content: `İstanbul'da sıfır kepenk sistemleri konusunda uzman ekibimizle hizmetinizdeyiz. Olgun Kepenk olarak, modern ve güvenilir otomatik kepenk sistemleri sunuyoruz. Sıfır kepenk tercihlerinizde kalite ve uygun fiyat garantisi veriyoruz.

        Sıfır kepenk sistemlerimiz, en son teknoloji ürünü motorlar ve elektronik aksamlarla donatılmıştır. İşyerinizin ihtiyaçlarına özel sıfır kepenk çözümleri üretiyor, profesyonel montaj hizmeti sunuyoruz. Tüm sıfır kepenk sistemlerimiz 2 yıl garanti kapsamındadır.

        İstanbul'un tüm ilçelerinde sıfır kepenk montajı yapıyoruz. Ücretsiz keşif hizmetimizle, mekanınıza en uygun sıfır kepenk sistemini belirliyor ve detaylı fiyat teklifi sunuyoruz.`
      },
      {
        title: 'Sıfır Kepenk Sistemlerimiz',
        content: `İşyerinizin ihtiyaçlarına uygun sıfır kepenk seçeneklerimiz:

        • Galvaniz Çelik Kepenk
        • Alüminyum Kepenk
        • Poliüretan Dolgulu Kepenk
        • Şeffaf Polikarbon Kepenk
        • Paslanmaz Çelik Kepenk
        • Dekoratif Kepenk
        • Endüstriyel Kepenk

        Tüm sıfır kepenk sistemlerimizde kaliteli malzeme ve profesyonel işçilik garantisi veriyoruz.`
      },
      {
        title: 'Neden Olgun Kepenk\'ten Sıfır Kepenk?',
        content: `İstanbul'da sıfır kepenk sistemleri için bizi tercih etmenizin nedenleri:

        • 20 Yıllık Sektör Deneyimi
        • Kaliteli Malzeme Garantisi
        • Profesyonel Montaj Ekibi
        • 2 Yıl Ürün Garantisi
        • Ücretsiz Keşif Hizmeti
        • Uygun Fiyat Politikası
        • 7/24 Teknik Destek

        Sıfır kepenk sistemlerinde kalite ve güvenilirlik için Olgun Kepenk'i tercih edin.`
      }
    ],
    metaDescription: 'İstanbul\'da sıfır otomatik kepenk sistemleri. ✓Modern Tasarım ✓Profesyonel Montaj ✓2 Yıl Garanti ✓Ücretsiz Keşif. Sıfır kepenk fiyatları için hemen arayın! ☎ 0552 858 00 60',
    keywords: 'sıfır kepenk, otomatik kepenk, kepenk sistemleri, İstanbul kepenk, otomatik kepenk fiyatları, kepenk montajı, galvaniz kepenk, alüminyum kepenk',
    faqs: [
      {
        question: 'Sıfır kepenk fiyatları neye göre belirlenir?',
        answer: 'Sıfır kepenk fiyatları; kepenk ölçüleri, malzeme tipi (galvaniz, alüminyum vb.), motor gücü ve ek özelliklere göre belirlenir. Ücretsiz keşif sonrası net fiyat bilgisi veriyoruz.'
      },
      {
        question: 'Sıfır kepenk montajı ne kadar sürer?',
        answer: 'Sıfır kepenk montajı genellikle 1 gün içinde tamamlanır. Özel projelerde bu süre değişebilir. Montaj öncesi net süre bilgisi veriyoruz.'
      },
      {
        question: 'Sıfır kepenk sistemleri garantili mi?',
        answer: 'Evet, tüm sıfır kepenk sistemlerimiz 2 yıl garanti kapsamındadır. Garanti süresi boyunca ücretsiz servis ve bakım hizmeti sunuyoruz.'
      },
      {
        question: 'Hangi tür sıfır kepenk sistemleri sunuyorsunuz?',
        answer: 'Galvaniz çelik, alüminyum, poliüretan dolgulu, şeffaf polikarbon, paslanmaz çelik ve dekoratif kepenk sistemleri sunuyoruz. Her ihtiyaca uygun çözümümüz mevcuttur.'
      },
      {
        question: 'Sıfır kepenk için ücretsiz keşif yapıyor musunuz?',
        answer: 'Evet, İstanbul\'un tüm ilçelerinde ücretsiz keşif hizmeti veriyoruz. Keşif sonrası detaylı fiyat teklifi sunuyoruz.'
      },
      {
        question: 'Sıfır kepenk sistemlerinde hangi marka motorlar kullanıyorsunuz?',
        answer: 'Nice, Somfy, BFT, Came gibi dünya lideri markaların motorlarını kullanıyoruz. Motor seçimi projenin özelliklerine göre yapılır.'
      },
      {
        question: 'Sıfır kepenk montajı sonrası servis desteği veriyor musunuz?',
        answer: 'Evet, montaj sonrası 7/24 teknik destek ve servis hizmeti sunuyoruz. Periyodik bakım hizmetimizle kepenk sisteminizin ömrünü uzatıyoruz.'
      },
      {
        question: 'Sıfır kepenk için ödeme seçenekleri nelerdir?',
        answer: 'Nakit, havale/EFT ve kredi kartı ile ödeme seçeneklerimiz mevcuttur. Kurumsal müşterilerimiz için özel ödeme planları sunuyoruz.'
      }
    ]
  },
  'kepenk-parcalari': {
    title: 'Kepenk Parçaları | Otomatik Kepenk Yedek Parça',
    description: 'Kaliteli ve uygun fiyatlı otomatik kepenk parçaları. Tüm kepenk sistemleri için yedek parça ve aksesuar çeşitleri.',
    image: '/assets/service/service-4.webp',
    sections: [{ products }],
    metaDescription: 'Otomatik kepenk sistemleri için kaliteli yedek parçalar. Motor, kumanda, ray sistemi ve tüm mekanik parçalar için profesyonel çözümler.',
    keywords: 'kepenk parçaları, kepenk motoru, kepenk kumandası, kepenk yayı, otomatik kepenk parçaları, kepenk yedek parça',
    faqs: []
  }
};

const ServiceDetail = ({ service }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const serviceData = services[service];

  const formatWhatsAppMessage = (product) => {
    if (!product) return '';
    
    return encodeURIComponent(
      `Merhaba,\n\n` +
      `Ürün: ${product.title}\n` +
      `Açıklama: ${product.description}\n\n` +
      `Bu ürün için güncel fiyat bilgisi alabilir miyim?`
    );
  };

  if (!serviceData) {
    return (
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-8">Sayfa Bulunamadı</h1>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-12">
      <Helmet>
        <title>{serviceData.title} - Olgun Kepenk</title>
        <meta name="description" content={serviceData.metaDescription} />
        <meta name="keywords" content={serviceData.keywords} />
      </Helmet>

      {/* Hero Section */}
      <div className="relative h-[400px] mb-12 rounded-xl overflow-hidden">
        <img
          src={serviceData.image}
          alt={serviceData.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
          <div className="text-center text-white">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">{serviceData.title}</h1>
            <p className="text-xl md:text-2xl max-w-3xl mx-auto px-4">
              {serviceData.description}
            </p>
          </div>
        </div>
      </div>

      {/* Products Grid */}
      {service === 'kepenk-parcalari' && (
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {products.map((product) => (
              <div key={product.id} className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300">
                <div className="relative h-[250px]">
                  <img
                    src={product.image}
                    alt={product.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold text-primary mb-3">{product.title}</h3>
                  <p className="text-gray-600 mb-4 min-h-[80px]">{product.description}</p>
                  <p className="text-2xl font-bold text-primary mb-6">{product.price} TL</p>
                  
                  <div className="grid grid-cols-2 gap-4">
                    <a
                      href={`tel:${contactConfig.phone}`}
                      className="flex items-center justify-center gap-2 bg-[#00bcd4] text-white py-3 px-4 rounded-lg hover:bg-[#00acc1] transition-colors"
                    >
                      <FiPhoneCall className="text-xl" />
                      Ara
                    </a>
                    <a
                      href={`https://wa.me/${contactConfig.whatsapp}?text=${formatWhatsAppMessage(product)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center gap-2 bg-[#25d366] text-white py-3 px-4 rounded-lg hover:bg-[#22c55e] transition-colors"
                    >
                      <BsWhatsapp className="text-xl" />
                      WhatsApp
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Other sections for different services */}
      {service !== 'kepenk-parcalari' && (
        <div className="max-w-4xl mx-auto">
          {/* Price Calculator only for sifir-kepenk page */}
          {service === 'sifir-kepenk' && <PriceCalculator />}

          {serviceData.sections.map((section, index) => (
            <div key={index} className="mb-16">
              <h2 className="text-3xl font-bold text-primary mb-6">{section.title}</h2>
              <div className="prose prose-lg max-w-none mb-8">
                {section.content.split('\n').map((paragraph, pIndex) => (
                  <p key={pIndex} className="mb-4 text-gray-600">
                    {paragraph.trim()}
                  </p>
                ))}
              </div>

              {/* Gallery for other services */}
              {index === 0 && serviceData.gallery && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                  {serviceData.gallery.map((image, imgIndex) => (
                    <div key={imgIndex} className="relative h-[300px] rounded-xl overflow-hidden shadow-lg">
                      <img
                        src={image}
                        alt={`${serviceData.title} - Görsel ${imgIndex + 2}`}
                        className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* FAQ Section */}
      {serviceData.faqs.length > 0 && (
        <div className="max-w-4xl mx-auto mt-16">
          <h2 className="text-3xl font-bold text-primary mb-8 text-center">Sıkça Sorulan Sorular</h2>
          <div className="space-y-6">
            {serviceData.faqs.map((faq, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-3">{faq.question}</h3>
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* CTA Section */}
      <div className="max-w-4xl mx-auto mt-16 text-center">
        <h2 className="text-3xl font-bold text-primary mb-6">
          Profesyonel Hizmet için Bize Ulaşın
        </h2>
        <p className="text-xl text-gray-600 mb-8">
          7/24 teknik destek ve ücretsiz keşif hizmeti için hemen arayın!
        </p>
        <a
          href={`tel:${contactConfig.phone}`}
          className="group relative inline-flex items-center gap-4 bg-primary hover:bg-primary-dark text-white px-8 py-4 rounded-full text-xl font-semibold transition-all duration-300 hover:scale-105 hover:shadow-lg overflow-hidden"
        >
          <span className="relative z-10 flex items-center gap-4">
            <span className="relative">
              <FiPhoneCall className="w-6 h-6" />
              <span className="absolute -inset-1 border-2 border-white/40 rounded-full animate-ping" />
              <span className="absolute -inset-2 border-2 border-white/20 rounded-full animate-ping animation-delay-300" />
            </span>
            Hemen Arayın
          </span>
          <span className="absolute inset-0 bg-gradient-to-r from-primary-dark to-primary opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        </a>
      </div>
    </div>
  );
};

export default ServiceDetail; 