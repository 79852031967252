import React from 'react';
import { useParams, Link, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { knowledgeBaseContent } from '../config/knowledgeBase';
import { FaCalendarAlt, FaUser, FaFolder } from 'react-icons/fa';

const GuideDetail = () => {
  const { slug } = useParams();
  const guide = knowledgeBaseContent.guides.find((g) => g.slug === slug);

  if (!guide) {
    return <Navigate to="/bilgi-merkezi" />;
  }

  // İlgili rehberler
  const relatedGuides = knowledgeBaseContent.guides
    .filter((g) => g.category === guide.category && g.id !== guide.id)
    .slice(0, 3);

  return (
    <>
      <Helmet>
        <title>{guide.title} | Olgun Kepenk</title>
        <meta name="description" content={guide.excerpt} />
        <meta
          name="keywords"
          content={`${guide.title}, ${guide.category}, kepenk rehberi, kepenk kılavuzu`}
        />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Hero Image */}
          <div className="relative h-[400px] rounded-xl overflow-hidden mb-8">
            <img
              src={guide.image}
              alt={guide.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50" />
            <div className="absolute inset-0 flex flex-col justify-center p-8 text-white">
              <span className="inline-block px-4 py-2 bg-primary rounded-full text-sm mb-4">
                {guide.category}
              </span>
              <h1 className="text-4xl font-bold mb-4">{guide.title}</h1>
              <p className="text-lg opacity-90">{guide.excerpt}</p>
            </div>
          </div>

          {/* Meta Bilgileri */}
          <div className="flex items-center gap-6 text-gray-600 mb-8">
            <div className="flex items-center">
              <FaCalendarAlt className="mr-2" />
              {guide.date}
            </div>
            <div className="flex items-center">
              <FaUser className="mr-2" />
              {guide.author}
            </div>
            <div className="flex items-center">
              <FaFolder className="mr-2" />
              {guide.category}
            </div>
          </div>

          {/* İçerik */}
          <div
            className="prose max-w-none mb-12"
            dangerouslySetInnerHTML={{ __html: guide.content }}
          />

          {/* İlgili Rehberler */}
          {relatedGuides.length > 0 && (
            <div>
              <h2 className="text-2xl font-bold mb-6">İlgili Rehberler</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {relatedGuides.map((relatedGuide) => (
                  <Link
                    key={relatedGuide.id}
                    to={`/bilgi-merkezi/rehber/${relatedGuide.slug}`}
                    className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                  >
                    <img
                      src={relatedGuide.image}
                      alt={relatedGuide.title}
                      className="w-full h-48 object-cover"
                    />
                    <div className="p-4">
                      <h3 className="font-bold mb-2">{relatedGuide.title}</h3>
                      <p className="text-gray-600 text-sm">
                        {relatedGuide.excerpt}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GuideDetail; 