import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const BlogDetail = ({ post }) => {
  if (!post) return null;

  return (
    <>
      <Helmet>
        <title>{post.title} | Olgun Kepenk Blog</title>
        <meta name="description" content={post.metaDescription} />
        <meta name="keywords" content={post.metaKeywords} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.metaDescription} />
        <meta property="og:image" content={post.image} />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.metaDescription} />
        <meta name="twitter:image" content={post.image} />

        {/* Article Specific */}
        <meta property="article:published_time" content={post.date} />
        <meta property="article:author" content={post.author} />
        {post.tags.map((tag) => (
          <meta property="article:tag" content={tag} key={tag} />
        ))}
      </Helmet>

      {/* Hero Section */}
      <div className="relative h-[60vh] min-h-[500px] bg-gray-900">
        <img
          src={post.image}
          alt={post.title}
          className="absolute inset-0 w-full h-full object-cover opacity-50"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/50 to-transparent">
          <div className="container mx-auto h-full px-4 flex items-end pb-16">
            <div className="max-w-4xl">
              <div className="flex items-center space-x-4 text-white/80 mb-4">
                <span className="flex items-center">
                  <svg className="w-5 h-5 text-primary mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                  {post.author}
                </span>
                <span>•</span>
                <span className="flex items-center">
                  <svg className="w-5 h-5 text-primary mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                  <time dateTime={post.date}>
                    {new Date(post.date).toLocaleDateString('tr-TR', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </time>
                </span>
              </div>
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-4 leading-tight">
                {post.title}
              </h1>
              <p className="text-xl text-white/80 max-w-3xl">
                {post.excerpt}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="bg-gray-50">
        <div className="container mx-auto px-4 py-12">
          <div className="flex flex-col lg:flex-row gap-12">
            {/* Main Content */}
            <article className="w-full lg:w-2/3">
              <div className="bg-white rounded-2xl shadow-xl overflow-hidden p-8 mb-8">
                <div className="flex flex-wrap gap-3 mb-8">
                  {post.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center bg-primary/10 text-primary text-sm px-4 py-2 rounded-full transform hover:scale-105 transition-transform"
                    >
                      <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                      </svg>
                      {tag}
                    </span>
                  ))}
                </div>

                <div 
                  className="prose prose-lg max-w-none prose-headings:text-primary prose-headings:font-bold prose-p:text-gray-600 prose-a:text-primary prose-a:no-underline hover:prose-a:text-primary-dark prose-img:rounded-xl prose-h2:text-2xl prose-h3:text-xl"
                  dangerouslySetInnerHTML={{ __html: post.content }}
                />
              </div>
            </article>

            {/* Sidebar */}
            <aside className="w-full lg:w-1/3 space-y-8 lg:sticky lg:top-8">
              {/* Contact Card */}
              <div className="bg-gradient-to-br from-primary to-primary-dark rounded-2xl shadow-xl overflow-hidden">
                <div className="p-8 text-white">
                  <h3 className="text-2xl font-bold mb-4">
                    7/24 Kepenk Servisi
                  </h3>
                  <p className="text-white/90 mb-6">
                    Profesyonel kepenk servisimiz ile 30 dakika içinde yanınızdayız.
                  </p>
                  <Link
                    to="/iletisim"
                    className="inline-flex items-center justify-center w-full bg-white text-primary hover:bg-gray-100 font-medium py-3 px-6 rounded-xl transition-colors group"
                  >
                    Hemen İletişime Geçin
                    <svg className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </Link>
                </div>
              </div>

              {/* Services Card */}
              <div className="bg-white rounded-2xl shadow-xl p-8">
                <h3 className="text-2xl font-bold text-primary mb-6">
                  Hizmetlerimiz
                </h3>
                <ul className="space-y-4">
                  {[
                    { title: 'Otomatik Kepenk Servisi', path: '/kepenk-servisi' },
                    { title: 'Kepenk Tamiri', path: '/kepenk-tamiri' },
                    { title: 'Sıfır Kepenk', path: '/sifir-kepenk' },
                    { title: 'Kepenk Parçaları', path: '/kepenk-parcalari' }
                  ].map((service, index) => (
                    <li key={index}>
                      <Link 
                        to={service.path} 
                        className="flex items-center p-4 rounded-xl hover:bg-gray-50 text-gray-600 hover:text-primary transition-colors group"
                      >
                        <span className="w-8 h-8 rounded-lg bg-primary/10 text-primary flex items-center justify-center mr-3 group-hover:bg-primary group-hover:text-white transition-colors">
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                          </svg>
                        </span>
                        {service.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Tags Card */}
              <div className="bg-white rounded-2xl shadow-xl p-8">
                <h3 className="text-2xl font-bold text-primary mb-6">
                  Popüler Konular
                </h3>
                <div className="flex flex-wrap gap-2">
                  {['otomatik kepenk', 'kepenk tamiri', 'kepenk servisi', 'kepenk bakımı', 'güvenlik sistemleri'].map((tag, index) => (
                    <span
                      key={index}
                      className="inline-block bg-gray-100 hover:bg-gray-200 text-gray-700 text-sm px-4 py-2 rounded-lg cursor-pointer transition-colors"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetail; 