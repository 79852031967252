import React from 'react';
import { Link } from 'react-router-dom';

const ServiceCard = ({ title, description, icon, features, href }) => {
  return (
    <Link to={href} className="block">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-xl">
        <div className="p-6">
          <div className="flex items-center justify-center w-16 h-16 bg-primary/10 rounded-full mb-4 mx-auto">
            {icon}
          </div>
          <h3 className="text-2xl font-bold text-center mb-4 text-primary">{title}</h3>
          <p className="text-gray-600 mb-6 text-center">{description}</p>
          {features && features.length > 0 && (
            <ul className="space-y-2">
              {features.map((feature, index) => (
                <li key={index} className="flex items-center text-gray-600">
                  <svg
                    className="w-5 h-5 text-primary mr-2 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ServiceCard; 