import React from 'react';
import { useParams, Link, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { knowledgeBaseContent } from '../config/knowledgeBase';
import { FaBook, FaTools, FaFileAlt } from 'react-icons/fa';

const GuideCategory = () => {
  const { categoryId } = useParams();
  const category = knowledgeBaseContent.categories.find(c => c.id === categoryId);
  
  if (!category) {
    return <Navigate to="/bilgi-merkezi" />;
  }

  // Kategoriye ait rehberleri filtrele
  const categoryGuides = knowledgeBaseContent.guides.filter(
    guide => guide.category === category.title
  );

  // Kategori ikonunu belirle
  const getCategoryIcon = () => {
    switch (categoryId) {
      case 'kullanim-kilavuzlari':
        return <FaBook size={32} />;
      case 'bakim-tavsiyeleri':
        return <FaTools size={32} />;
      case 'teknik-dokumanlar':
        return <FaFileAlt size={32} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>{category.title} | Olgun Kepenk Bilgi Merkezi</title>
        <meta name="description" content={category.description} />
        <meta
          name="keywords"
          content={`${category.title}, kepenk rehberi, kepenk bilgi merkezi, olgun kepenk`}
        />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        {/* Kategori Başlık */}
        <div className="bg-gradient-to-r from-primary to-primary/80 rounded-xl p-8 mb-12 text-white">
          <div className="flex items-center gap-4 mb-4">
            <div className="text-white">
              {getCategoryIcon()}
            </div>
            <h1 className="text-4xl font-bold">{category.title}</h1>
          </div>
          <p className="text-lg opacity-90">{category.description}</p>
        </div>

        {/* Rehberler Listesi */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {categoryGuides.map((guide) => (
            <Link
              key={guide.id}
              to={`/bilgi-merkezi/rehber/${guide.slug}`}
              className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            >
              <img
                src={guide.image}
                alt={guide.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h2 className="text-xl font-bold mb-2">{guide.title}</h2>
                <p className="text-gray-600 mb-4">{guide.excerpt}</p>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500">{guide.date}</span>
                  <span className="text-primary hover:text-primary/80">
                    Devamını Oku →
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {categoryGuides.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-600">Bu kategoride henüz rehber bulunmuyor.</p>
            <Link
              to="/bilgi-merkezi"
              className="inline-block mt-4 text-primary hover:text-primary/80"
            >
              ← Bilgi Merkezine Dön
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default GuideCategory; 