import React from 'react';
import ServiceCard from './ServiceCard';

const services = [
  {
    title: 'Otomatik Kepenk Servisi',
    description: '7/24 profesyonel otomatik kepenk servis hizmeti ile işyerinizin güvenliğini garanti altına alıyoruz.',
    href: '/kepenk-servisi',
    icon: (
      <svg
        className="w-8 h-8 text-primary"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    ),
    features: [
      'Periyodik bakım hizmetleri',
      'Acil servis desteği',
      'Yerinde servis hizmeti',
      'Garantili yedek parça',
    ],
  },
  {
    title: 'Otomatik Kepenk Tamiri',
    description: 'Deneyimli ekibimizle her türlü otomatik kepenk arızasına hızlı ve kalıcı çözümler sunuyoruz.',
    href: '/kepenk-tamiri',
    icon: (
      <svg
        className="w-8 h-8 text-primary"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
        />
      </svg>
    ),
    features: [
      'Motor arızası tamiri',
      'Kumanda sistemi onarımı',
      'Mekanik aksam tamiri',
      'Güvenlik sistemi tamiri',
    ],
  },
  {
    title: 'Sıfır Kepenk',
    description: 'Modern ve güvenilir sıfır kepenk sistemleri ile işyerinizi güçlendiriyoruz.',
    href: '/sifir-kepenk',
    icon: (
      <svg
        className="w-8 h-8 text-primary"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
        />
      </svg>
    ),
    features: [
      'Yeni nesil kepenk sistemleri',
      'Uzaktan kumandalı sistemler',
      'Güvenlik sensörleri',
      'Profesyonel montaj',
    ],
  },
  {
    title: 'Kepenk Parçaları',
    description: 'Kaliteli ve uygun fiyatlı otomatik kepenk yedek parçaları ile hizmetinizdeyiz.',
    href: '/kepenk-parcalari',
    icon: (
      <svg
        className="w-8 h-8 text-primary"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
        />
      </svg>
    ),
    features: [
      'Kepenk motorları',
      'Kumanda sistemleri',
      'Mekanik parçalar',
      'Orijinal ürünler',
    ],
  },
];

const HomeServices = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center text-primary mb-8">
        Olgun Kepenk - Profesyonel Kepenk Çözümleri
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            description={service.description}
            icon={service.icon}
            features={service.features}
            href={service.href}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeServices; 